import authService from "~/services/authService";
import userService from "~/services/userService";
import Constants from "~/helpers/enums/Constants";
import Utils from "~/helpers/Utils";
import jwtDecode from "jwt-decode";

const sendCredentials = (values, callback = () => {}, LOADING_IDENTIFICATOR = "header") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

	return authService
		.login(values)
		.then((response) => {
			if (response) {
				Utils.setToken(response.data.accessToken);
				Utils.setToken(response.data.acceptanceTerm, Constants.TOKEN_ACCEPT_TEM);
				Utils.setToken(response.data.temporaryPassword, Constants.TOKEN_TEMPORARY_PASSWORD);
				callback();
				const token = jwtDecode(response.data.accessToken);
				dispatch({
					type: Constants.LOGIN,
					payload: {
						user: {
							name: Utils.getName(token.name),
							sub: token.sub,
							role: token.functionalities || null,
							authorities: token.authorities || []
						},
						isTemporaryPassword: response.data.temporaryPassword,
						acceptanceTerm: response.data.acceptanceTerm,
					},
				});
			}
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const verifyCredentialsAuthentication = (isAuthenticated) => (dispatch) => {
	if (Utils.not(isAuthenticated) && Utils.hasTokenValid()) {
		dispatch({ type: Constants.LOGIN, payload: {} });
	} else if (Utils.not(Utils.hasTokenValid())) {
		dispatch({ type: Constants.LOGOUT });
	}
};

const logout = () => (dispatch) => {
	dispatch({ type: Constants.LOGOUT });
	Utils.removeToken();
};

const getProfile = () => (dispatch, callback = () => {}, LOADING_IDENTIFICATOR = "profile") => {
	const token = Utils.getToken();
	if (token) {
		const tokenDecode = jwtDecode(token);
		dispatch({
			type: Constants.LOGIN,
			payload: {
				user: {
					name: Utils.getName(tokenDecode.name),
					sub: tokenDecode.sub,
					role: tokenDecode.functionalities || null,
					authorities: tokenDecode.authorities || []
				},
				isTemporaryPassword: Utils.getToken(Constants.TOKEN_TEMPORARY_PASSWORD),
				acceptanceTerm: Utils.getToken(Constants.TOKEN_ACCEPT_TEM),
			},
		});
	}
};

const sendForgetPassword = (values, callback = () => {}, LOADING_IDENTIFICATOR = "header") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

	return authService
		.sendForgetPassword(values)
		.then((response) => {
			if (response) {
				callback();
				dispatch({
					type: Constants.FORGET_PASSWORD,
					payload: { email: values.email },
				});
			}
		})
		.catch((error) => {
			if (error.response) callback(error.response.data);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const cleanForgetPassword = () => (dispatch) => {
	dispatch({
		type: Constants.CLEAN_FORGET_PASSWORD,
	});
};

const registerPassword = (values, callback = () => {}, LOADING_IDENTIFICATOR = "header") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

	return userService
		.registerPassword(values)
		.then((response) => {
			if (response) {
				dispatch({type: Constants.SET_TEMPORARY_PASSWORD_FALSE })
				callback();
			}
		})
		.catch((error) => {
			callback(error);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const sendUserTem = (callback = () => {}, LOADING_IDENTIFICATOR = "acceptTerm") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));

	return userService
		.sendUserTem()
		.then(() => {
			callback();
			dispatch({
				type: Constants.ACCEPT_TERM,
			});
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

export default {
	sendCredentials,
	verifyCredentialsAuthentication,
	logout,
	getProfile,
	sendForgetPassword,
	cleanForgetPassword,
	registerPassword,
	sendUserTem,
};
