import Constants from '~/helpers/enums/Constants';

const initialState = {
  functionality: [],
  profileFunctionalities: []
};

function functionalityReducer(state = initialState, action = null) {
  switch (action.type) {
    case Constants.GET_FUNCTIONALITY:
      return {
        ...state,
        functionality: action.payload,
      };
    case Constants.GET_PROFILE_FUNCTIONALITIES:
      return {
        ...state,
        profileFunctionalities: action.payload
      }
    default:
      return state;
  }
}

export default functionalityReducer;
