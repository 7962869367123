import { makeStyles } from "@material-ui/core/styles";

const MuiStyleAutoComplete = makeStyles((theme) => ({
	chip: {
		margin: 0,
    padding: 0,
    borderRadius: theme.spacing(0.7)
  },
  checkboxCombo: {
    height: "3.875rem",
    marginRight: "0rem",
    "&:hover": {
      backgroundColor:" #0000000A",
      marginRight: "0rem"
    }
  },
  checkboxTrue: {
    backgroundColor: "#00000014",
  },
  checkboxChildren: {
    paddingLeft: "1rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    "&:focus": {
      backgroundColor: "none"
    }
  }
}));

export default MuiStyleAutoComplete;
