import Constants from "~/helpers/enums/Constants";

const INITIAL_STATE = {
	editForm: {},
	openRejectDialog: false,
	ownData: {},
	ownSuppliers: []
};

function userReducer(state = INITIAL_STATE, action = null) {
	switch (action.type) {
		case Constants.GET_USER:
			return {
				...state,
				editForm: action.payload,
			};
			case Constants.CLEAR_USER:
				return {
					...state,
					editForm: INITIAL_STATE.editForm,
				};
		case Constants.OPEN_REJECT_DIALOG:
			return {
				...state,
				openRejectDialog: true,
			};
		case Constants.CLOSE_REJECT_DIALOG:
			return {
				...state,
				openRejectDialog: false,
			};
		case Constants.GET_OWN_DATA:
			return {
				...state,
				ownData: action.payload
			}
		case Constants.GET_OWN_SUPPLIER: 
			return {
				...state,
				ownSuppliers: action.payload || []
			}
		default:
			return state;
	}
}

export default userReducer;
