import Constants from "~/helpers/enums/Constants";

const initialState = {
	positions: [],
};

function positionReducer(state = initialState, action = null) {
	switch (action.type) {
		case Constants.GET_POSITION:
			return {
				...state,
				positions: action.payload,
			};
		default:
			return state;
	}
}

export default positionReducer;
