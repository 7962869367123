import Constants from "~/helpers/enums/Constants";

const INITIAL_STATE = {
	loading: {},
	dialog: {
		open: false,
	},
	background: "",
	customDialog: {
		open: false,
		message: "",
		theme: null,
	},
};

function appReducer(state = INITIAL_STATE, action = null) {
	switch (action.type) {
		case Constants.UPDATE_LOADING:
			return {
				...state,
				loading: {
					...state.loading,
					...action.loading,
				},
			};
		case Constants.OPEN_CONFIRM_DIALOG:
			return {
				...state,
				dialog: {
					open: true,
					...action.payload.params,
				},
			};
		case Constants.CLOSE_CONFIRM_DIALOG:
			return {
				...state,
				dialog: INITIAL_STATE.dialog,
			};
		case Constants.SET_BACKGROUND: {
			return {
				...state,
				background: action.payload,
			};
		}
		case Constants.OPEN_CUSTOM_DIALOG:
			return {
				...state,
				customDialog: {
					open: true,
					...action.payload.params,
				},
			};
		case Constants.CLOSE_CUSTOM_DIALOG:
			return {
				...state,
				customDialog: {
					...state.customDialog,
					open: false,
				},
			};
		default:
			return state;
	}
}

export default appReducer;
