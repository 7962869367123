import { makeStyles } from "@material-ui/core/styles";

const MuiStylesDialogSupplier = makeStyles((theme) => ({
	button: {
		borderRadius: "6px",
		textTransform: "none",
	},
	cancelButton: {
		margin: theme.spacing(1, 1, 1, 0),
	},
	saveButton: {
		margin: theme.spacing(1),
	},
	contentDialog: {
		minHeight: theme.spacing(25),
	},
	footerDialog: {
		display: "flex",
	},
	dialogSupplierTitleContracts: {
		color: "#a3b1d4",
		textAlign: "left",
	},
	dialogSupplierTitlePortal: {
		color: "#8D8D8D",
		textAlign: "center",
	},
}));

export default MuiStylesDialogSupplier;
