import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	IconButton,
	MuiThemeProvider,
	Slide,
	Typography
} from "@material-ui/core";
import React, { forwardRef } from "react";
import { CloseIcon } from "../icons/Icons";
import SpinnerLoading from "../spinnerLoading/SpinnerLoading";
import MuiStylesCustomDialog from "./MuiStylesCustomDialog";
import MuiThemeCustomDialog from "./MuiThemeCustomDialog";

const SlideUp = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const CustomDialog = (props) => {
	const { open, onClose, id, loading } = props;
	const classes = MuiStylesCustomDialog();

	return (
		<MuiThemeProvider theme={props.theme || MuiThemeCustomDialog}>
			<Dialog
				fullWidth
				maxWidth={props.maxWidth || false}
				open={open}
				TransitionComponent={SlideUp}
				onClose={onClose}
				id={`customDialog-${id}`}>
				<SpinnerLoading loading={loading}>
					<DialogTitle>
						{
							<Grid container direction="row" justify="space-between">
								<Grid item>
									<Typography className={classes.detailsTitle}>{props.title}</Typography>
								</Grid>
								{props.closeButton && (
									<Grid item>
										<IconButton aria-label="fechar" onClick={onClose}>
											<CloseIcon />
										</IconButton>
									</Grid>
								)}
							</Grid>
						}
					</DialogTitle>
					<DialogContent>
						{props.text ? (
							<>
								<DialogContentText>{props.text}</DialogContentText>
								{props.children}
							</>
						) : (
							props.children
						)}
					</DialogContent>
					{props.actions && <DialogActions style={props.actionsStyle || null}>{props.actions}</DialogActions>}
				</SpinnerLoading>
			</Dialog>
		</MuiThemeProvider>
	);
};

export default CustomDialog;
