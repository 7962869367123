import Constants from "~/helpers/enums/Constants";

const initialState = {
	notifications: [],
	totalUnread: 0,
};

const notificationReducer = (state = initialState, action = null) => {
	switch (action.type) {
		case Constants.UPDATE_NOTIFICATIONS:
			return {
				notifications: action.payload.notifications,
				totalUnread: action.payload.totalUnread,
			};
		default:
			return state;
	}
};

export default notificationReducer;
