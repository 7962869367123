import http from "~/config/httpRequest/http";

const CONTROLLER_NAME = "api/suppliers";

export const getSupplier= (filter) => {
	return new Promise((resolve, reject) => {
		let params = {};
		if (filter)
			params = {
				text: filter.text,
      };
		http
			.get(`/${CONTROLLER_NAME}/`, {params})
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};
export default { getSupplier };
