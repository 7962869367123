import { combineReducers } from "redux";

// Application Reducers
import app from "~/reducers/appReducer";
import auth from "~/reducers/authReducer";
import language from "~/reducers/languageReducer";
import functionality from "~/reducers/functionalityReducer";
import sector from "~/reducers/sectorReducer";
import supplier from "~/reducers/supplierReducer";
import user from "~/reducers/userReducer";
import position from "~/reducers/positionReducer";
import notification from "~/reducers/notificationReducer";
import contract from "~/reducers/contractReducer";
import Constants from "~/helpers/enums/Constants";
import rubric from "~/reducers/rubricReducer"
import readjustment from '~/reducers/readjustmentReducer'

const appReducer = combineReducers({
	app,
	auth,
	language,
	functionality,
	sector,
	supplier,
	user,
	position,
	notification,
	contract,
	rubric,
	readjustment
});

const rootReducer = (state, action) => {
	if (action.type === Constants.LOGOUT) {
		state = undefined;
	}

	return appReducer(state, action);
};

export default rootReducer;
