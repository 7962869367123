import Constants from "~/helpers/enums/Constants";

const INITIAL_STATE = {
	dialog: {
		open: false,
	},
	filter: {
		suppliers: [],
	},
	suppliers: [],
};

function supplierReducer(state = INITIAL_STATE, action = null) {
	switch (action.type) {
		case Constants.OPEN_SUPPLIER_DIALOG:
			return {
				...state,
				dialog: {
					open: true,
					...action.payload.params,
				},
			};
		case Constants.CLOSE_SUPPLIER_DIALOG:
			return {
				...state,
				dialog: INITIAL_STATE.dialog,
			};
		case Constants.SAVES_SUPPLIER_FILTER:
			return {
				...state,
				filter: {
					suppliers: action.payload.suppliers,
				},
			};
		case Constants.GET_SUPPLIER:
			return {
				...state,
				suppliers: action.payload,
			};
		case Constants.CLEAR_SUPPLIER:
			return {
				...state,
				suppliers: INITIAL_STATE.suppliers,
			};
		default:
			return state;
	}
}

export default supplierReducer;
