import http from "~/config/httpRequest/http";

const CONTROLLER_NAME = "api/notifications";

export const getNotifications = () => {
	return new Promise((resolve, reject) => {
		http
			.get(`/${CONTROLLER_NAME}/`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

export default { getNotifications };
