import { createMuiTheme } from "@material-ui/core";

const MuiThemeDialogSupplier = (theme) =>
	createMuiTheme({
		...theme,
		overrides: {
			MuiDialog: {
				paperFullWidth: {
					borderRadius: "20px",
				},
			},
		},
	});

export default MuiThemeDialogSupplier;
