const { createMuiTheme } = require("@material-ui/core");

const MuiThemeBaseLayoutNotification = (theme) =>
	createMuiTheme({
		...theme,
		overrides: {
			MuiPopover: {
				paper: {
					width: "max-content",
				},
			},
			MuiPaper: {
				rounded: {
					borderRadius: "10px",
				},
			},
			MuiDivider: {
				inset: {
					marginLeft: "0px",
				},
			},
		},
	});

export default MuiThemeBaseLayoutNotification;
