import React from "react";

import Profiles from "~/helpers/enums/Profiles";
import Authorities from "~/helpers/enums/Authorities";
import Labels from "~/helpers/enums/Labels";

const Login = React.lazy(() => import("~/views/login/Login"));
const MenuNewUser = React.lazy(() => import("~/views/menuNewUser/MenuNewUser"));
const MyAccount = React.lazy(() => import("~/views/myAccount/MyAccount"));
const UserAdminForm = React.lazy(() => import("~/views/newUser/UserAdminForm"));
const UserCollaboratorForm = React.lazy(() => import("~/views/newUser/UserCollaboratorForm"));
const UserSupplierForm = React.lazy(() => import("~/views/newUser/UserSupplierForm"));
const Portal = React.lazy(() => import("~/views/portal/Portal"));
const PreRegistration = React.lazy(() => import("~/views/preRegistration/PreRegistration"));
const CreateReadjustment = React.lazy(() => import("~/views/readjustments/CreateReadjustment"));
const ListNegotiations = React.lazy(() => import("~/views/readjustments/ListNegotiations"));
const RegisterPassword = React.lazy(() => import("~/views/registerPassword/RegisterPassword"));
const EditReadjustment = React.lazy(() => import("~/views/readjustments/EditReadjustment"));
const Rubrics = React.lazy(() => import("~/views/rubrics/Rubrics"));
const Users = React.lazy(() => import("~/views/users/Users"));

const Anticipations = React.lazy(() => import("~/views/anticipations/Anticipations"));
const ApproveNewUser = React.lazy(() => import("~/views/approveNewUser/ApproveNewUser"));
const Contracts = React.lazy(() => import("~/views/contracts/Contracts"));
const AproveDetailContracts = React.lazy(() => import("~/views/detailContracts/AproveDetailContracts"));
const DetailContracts = React.lazy(() => import("~/views/detailContracts/DetailContracts"));
const EditNewUser = React.lazy(() => import("~/views/editNewUser/EditNewUser"));
const ForgetPassword = React.lazy(() => import("~/views/forgetPassword/ForgetPassword"));
const Invoices = React.lazy(() => import("~/views/invoices/Invoices"));

export const routes = [
	{
		path: "/login",
		exact: true,
		title: () => Labels.LOGIN_TITLE,
		view: () => <Login />,
		onlyAuthorized: false,
	},
	{
		path: "/",
		exact: true,
		view: () => <Portal />,
		onlyAuthorized: true,
		mainPage: true,
	},
	{
		path: "/portal",
		exact: true,
		view: () => <Portal />,
		onlyAuthorized: true,
		mainPage: true,
	},
	{
		path: "/pre-register",
		exact: true,
		title: () => Labels.PRE_REGISTER_TITLE,
		view: () => <PreRegistration />,
		onlyAuthorized: true,
		profilesAuthorized: [Profiles.FORNECEDOR_PRE_CADASTRAR],
	},
	{
		path: "/forget-password",
		exact: true,
		title: () => Labels.FORGET_PASSWORD_TITLE,
		view: () => <ForgetPassword />,
		onlyAuthorized: false,
	},
	{
		path: "/register-password",
		exact: true,
		title: () => Labels.REGISTER_PASSWORD_TITLE,
		view: () => <RegisterPassword />,
		onlyAuthorized: true,
		temporaryPasswordPage: true,
	},
	{
		path: "/new-user",
		exact: true,
		title: () => Labels.MENU_NEW_USER_TITLE,
		view: () => <MenuNewUser />,
		onlyAuthorized: true,
	},
	{
		path: "/new-user/supplier",
		exact: true,
		title: () => Labels.MENU_NEW_USER_TITLE,
		view: () => <UserSupplierForm />,
		onlyAuthorized: true,
		profilesAuthorized: [Profiles.ADMIN_CADASTRAR_USUARIO],
	},
	{
		path: "/new-user/collaborator",
		exact: true,
		title: () => Labels.MENU_NEW_USER_TITLE,
		view: () => <UserCollaboratorForm />,
		onlyAuthorized: true,
		profilesAuthorized: [Profiles.ADMIN_CADASTRAR_USUARIO],
	},
	{
		path: "/new-user/admin",
		exact: true,
		title: () => Labels.MENU_NEW_USER_TITLE,
		view: () => <UserAdminForm />,
		onlyAuthorized: true,
		profilesAuthorized: [Profiles.ADMIN_CADASTRAR_USUARIO],
	},
	{
		path: "/edit-user/:id",
		exact: true,
		title: () => Labels.EDIT_NEW_USER_TITLE,
		view: () => <EditNewUser />,
		onlyAuthorized: true,
		profilesAuthorized: [Profiles.ADMIN_APROVAR_USUARIO],
	},
	{
		path: "/approve-user/:id",
		exact: true,
		title: () => Labels.APROVE_USER_TITLE,
		view: () => <ApproveNewUser />,
		onlyAuthorized: true,
		profilesAuthorized: [Profiles.ADMIN_APROVAR_USUARIO],
	},
	{
		path: "/usuarios",
		exact: true,
		title: () => Labels.REGISTER_PASSWORD_TITLE,
		view: () => <Users />,
		onlyAuthorized: true,
		profilesAuthorized: [Profiles.ADMIN_APROVAR_USUARIO],
	},
	{
		path: "/minha-conta",
		exact: true,
		title: () => Labels.MY_ACCOUNT_TITLE,
		view: () => <MyAccount />,
		onlyAuthorized: true,
	},
	{
		path: "/contratos",
		exact: true,
		title: () => Labels.CONTRACTS_TITLE,
		view: () => <Contracts />,
		onlyAuthorized: true,
		profilesAuthorized: [Profiles.CONTRATOS_CONSULTAR, Profiles.CONTRATOS_EXECUTOR],
	},
	{
		path: "/contrato/:id",
		exact: true,
		title: () => "Contratos",
		view: () => <DetailContracts />,
		onlyAuthorized: true,
	},
	{
		path: "/aprovar-contrato/:id",
		exact: true,
		title: () => "Contratos",
		view: () => <AproveDetailContracts />,
		onlyAuthorized: true,
	},
	{
		path: "/rubricas",
		exact: true,
		view: () => <Rubrics />,
		onlyAuthorized: true,
		profilesAuthorized: [Profiles.RUBRICAS_CONSULTAR, Profiles.RUBRICAS_EXECUTOR],
	},
	{
		path: "/notas-fiscais",
		exact: true,
		view: () => <Invoices />,
		onlyAuthorized: true,
		profilesAuthorized: [Profiles.NFS_CONSULTAR, Profiles.NFS_EXECUTOR],
	},
	{
		path: "/antecipacoes",
		exact: true,
		view: () => <Anticipations />,
		onlyAuthorized: true,
		profilesAuthorized: [
			Profiles.ANTECIPACAO_EXECUTOR,
			Profiles.ANTECIPACAO_CONSULTAR,
			Profiles.ADMIN_APROVAR_ANTECIPACAO,
		],
	},
	{
		path: "/negotiations",
		exact: true,
		view: () => <ListNegotiations />,
		onlyAuthorized: true,
		profilesAuthorized: [
			Authorities.ADMIN,
			Authorities.EMPLOYEE,
			Profiles.TABELA_PRECOS_CONSULTAR,
			Profiles.TABELA_PRECOS_EXECUTOR,
		],
	},
	{
		path: "/negotiations/:id([0-9]+)",
		exact: true,
		view: () => <EditReadjustment />,
		onlyAuthorized: true,
		profilesAuthorized: [
			Authorities.ADMIN,
			Authorities.EMPLOYEE,
			Profiles.TABELA_PRECOS_CONSULTAR,
			Profiles.TABELA_PRECOS_EXECUTOR,
		],
	},
	{
		path: "/negotiations/create",
		exact: true,
		view: () => <CreateReadjustment />,		
		onlyAuthorized: true,
		profilesAuthorized: [
			Authorities.ADMIN,
			Authorities.EMPLOYEE,
			Profiles.TABELA_PRECOS_CONSULTAR,
			Profiles.TABELA_PRECOS_EXECUTOR,
		],
	},
];

export default routes;
