import Close from "@material-ui/icons/Close";
import Search from "@material-ui/icons/Search";
import React from "react";
import AcceptSvg from "~/assets/i/accept.svg";
import { ReactComponent as AguardandoSvg } from "~/assets/i/aguardando.svg";
import ArrowBackSvg from "~/assets/i/arrow-back.svg";
import ArrowDownBlueSvg from "~/assets/i/arrow-down-blue.svg";
import { ReactComponent as ArrowDownSvg } from "~/assets/i/arrow-down.svg";
import { ReactComponent as ArrowLeftSvg } from "~/assets/i/arrow-left.svg";
import { ReactComponent as ArrowRightSvg } from "~/assets/i/arrow-right.svg";
import { ReactComponent as ArrowUpSvg } from "~/assets/i/arrow-up.svg";
import { ReactComponent as AtivoSvg } from "~/assets/i/ativo.svg";
import { ReactComponent as AttentionSvg } from "~/assets/i/attention.svg";
import { ReactComponent as CalendarSvg } from "~/assets/i/calendar.svg";
import { ReactComponent as CampaignSvg } from "~/assets/i/campaign.svg";
import { ReactComponent as ChangeSupplierSvg } from "~/assets/i/change-supplier.svg";
import { ReactComponent as CheckSvg } from "~/assets/i/check.svg";
import { ReactComponent as ComprovanteSvg } from "~/assets/i/comprovante.svg";
import { ReactComponent as ErrorSvg } from "~/assets/i/error.svg";
import ExportSvg from "~/assets/i/export.svg";
import BirdSvg from "~/assets/i/icon-bird.svg";
import CatSvg from "~/assets/i/icon-cat.svg";
import DogSvg from "~/assets/i/icon-dog.svg";
import ImageSvg from "~/assets/i/image.svg";
import ImportSvg from "~/assets/i/import.svg";
import InativarUserSvg from "~/assets/i/inativar.svg";
import { ReactComponent as InativoSvg } from "~/assets/i/inativo.svg";
import LoginSvg from "~/assets/i/login.svg";
import BlueLogoSvg from "~/assets/i/logo_petz.svg";
import WhiteLogoSvg from "~/assets/i/logo-branca.svg";
import NotificationSvg from "~/assets/i/notificacao.svg";
import PrintSvg from "~/assets/i/print.svg";
import QuestionDogsSvg from "~/assets/i/question-dogs.png";
import RejectSvg from "~/assets/i/reject.svg";
import { ReactComponent as RelatorioSvg } from "~/assets/i/relatorio.svg";
import { ReactComponent as SimulacaoSvg } from "~/assets/i/simulacao.svg";
import StarSvg from "~/assets/i/star.svg";
import DeleteSvg from "~/assets/i/delete.svg";
import CheckGreenSvg from "~/assets/i/checkGreen.svg";
import FlagGreenSvg from "~/assets/i/flagGreen.svg";
import FlagRedSvg from "~/assets/i/flagRed.svg";
import WarnningSvg from "~/assets/i/warnning.svg";
import LoadingGif from "~/assets/i/loading.gif";
import "./Icons.scss";

export const BlueLogoIcon = (props) => <img src={BlueLogoSvg} alt={"logo-blue"} {...props} />;
export const NotificationIcon = (props) => <img src={NotificationSvg} alt={"notification"} {...props} />;
export const ArrowDownBlueIcon = (props) => <img src={ArrowDownBlueSvg} alt={"ArrowDown"} {...props} />;
export const WhiteLogoIcon = (props) => <img src={WhiteLogoSvg} alt={"Logo"} {...props} />;
export const LoginIcon = (props) => <img src={LoginSvg} alt={"Login-blue"} {...props} />;
export const QuestionDogsIcon = (props) => <img src={QuestionDogsSvg} alt={"question-dogs"} {...props} />;
export const DogIcon = (props) => <img src={DogSvg} alt={"icon-dog"} {...props} />;
export const CatIcon = (props) => <img src={CatSvg} alt={"icon-cat"} {...props} />;
export const BirdIcon = (props) => <img src={BirdSvg} alt={"icon-bird"} {...props} />;
export const ArrowBackIcon = (props) => <img src={ArrowBackSvg} alt={"arrow-back"} {...props} />;
export const StarIcon = (props) => <img src={StarSvg} alt={"star"} {...props} />;
export const ExportIcon = (props) => <img src={ExportSvg} alt={"exportar"} {...props} />;
export const ImportIcon = (props) => <img src={ImportSvg} alt={"importar"} {...props} />;
export const InativarUserIcon = (props) => <img src={InativarUserSvg} alt={"inativar"} {...props} />;
export const AguardandoIcon = AguardandoSvg;
export const AtivoIcon = AtivoSvg;
export const InativoIcon = InativoSvg;
export const ArrowUpIcon = ArrowUpSvg;
export const ArrowDownIcon = ArrowDownSvg;
export const ArrowRightIcon = ArrowRightSvg;
export const ArrowLeftIcon = ArrowLeftSvg;
export const PrintIcon = (props) => <img src={PrintSvg} alt={"imprimir"} {...props} />;
export const SearchIcon = Search;
export const CalendarIcon = CalendarSvg;
export const CampaignIcon = CampaignSvg;
export const ImageIcon = (props) => <img src={ImageSvg} alt={"images"} {...props} />;
export const CloseIcon = Close;
export const ErrorIcon = ErrorSvg;
export const SimulacaoIcon = SimulacaoSvg;
export const ComprovanteIcon = ComprovanteSvg;
export const RelatorioIcon = RelatorioSvg;
export const AcceptIcon = (props) => <img src={AcceptSvg} alt={"aprovar"} {...props} />;
export const RejectIcon = (props) => <img src={RejectSvg} alt={"recusar"} {...props} />;
export const AttentionIcon = AttentionSvg;
export const CheckIcon = CheckSvg;
export const ChangeSupplierIcon = ChangeSupplierSvg;
export const DeleteIcon = (props) => <img src={DeleteSvg} alt={"rejeitar"} {...props} />;
export const CheckGreenIcon = (props) => <img src={CheckGreenSvg} alt={"aceitar"} {...props} />;
export const FlagGreenIcon = (props) => <img src={FlagGreenSvg} alt={"aceito"} {...props} />;
export const FlagRedIcon = (props) => <img src={FlagRedSvg} alt={"recusado"} {...props} />;
export const WarnningIcon = (props) => <img src={WarnningSvg} alt={"cuidado"} {...props} />;
export const LoadingGifIcon = (props) => <img src={LoadingGif} alt="loading" {...props} />;
