import { createMuiTheme } from "@material-ui/core";

const MuiThemeCustomDialog = (theme) =>
	createMuiTheme({
		...theme,
		overrides: {
			MuiDialog: {
				paperFullWidth: {
					borderRadius: "20px",
				},
				paperWidthFalse: {
					maxWidth: `calc(100% - 150px)`,
				},
			},
			MuiDialogTitle: {
				root: {
					padding: theme.spacing(2, 3, 0),
				},
			},
			MuiBackdrop: {
				root: {
					backgroundColor: "rgba(0,0,0,0.8)",
				},
			},
		},
	});
export default MuiThemeCustomDialog;
