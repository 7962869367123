import React from "react";
import { AguardandoIcon, AtivoIcon, InativoIcon } from "~/components/common/icons/Icons";

export const UserStatus = [
	{ id: 1, description: "Ativo", component: <AtivoIcon key="status" /> },
	{ id: 2, description: "Inativo", component: <InativoIcon key="status" /> },
	{ id: 3, description: "Aguardando", component: <AguardandoIcon key="status" /> },
	{ id: 3, description: "Aguardando outra aprovação", component: <AguardandoIcon key="status" /> }
];

export const ContractStatus = [
	{ id: 1, id_string: "01", description: "Aguardando", component: <AguardandoIcon key="status" /> },
	{ id: 2, id_string: "02", description: "Ativo", component: <AtivoIcon key="status" /> },
	{ id: 3, id_string: "03", description: "Rejeitado", component: <InativoIcon key="status" /> },
	{ id: 4, id_string: "04", description: "Inativo", component: <InativoIcon key="status" /> },
	{ id: 5, id_string: "05", description: "Desabilitado", component: <InativoIcon key="status" /> },
	{ id: 0, id_string: "X0", description: "Expirado", component: <InativoIcon key="status" /> },
];

export default { UserStatus, ContractStatus };
