// General
const NO_DATA = "general.no_data";
const NO_SEARCH_YET = "general.no_search_yet";
const PAGINATION = "general.pagination";
const CURRENCY = "general.currency";
const LOCALE = "general.locale";
const SOON = "general.soon";

// Login
const LOGIN_TITLE = "login.title";
const LOGIN_USER = "login.user.name";
const LOGIN_USER_REQUIRED = "login.user.required";
const LOGIN_PASSWORD = "login.password.name";
const LOGIN_PASSWORD_REQUIRED = "login.password.required";
const LOGIN_SUBMIT = "login.submit";
const LOGIN_ACCESS_DENIED = "login.messages.access_denied";
const LOGIN_FORGET_PASSWORD = "login.forget_password";
const LOGIN_FORGET_REMEMBER_ME = "login.remember_me";

//ForgetPassword
const FORGET_PASSWORD_TITLE = "forget_password.title";
const FORGET_PASSWORD_EMAIL = "forget_password.form.email.name";
const FORGET_PASSWORD_EMAIL_REQUIRED = "forget_password.form.email.required";
const FORGET_PASSWORD_SEND_BUTTON = "forget_password.form.send_button";
const FORGET_PASSWORD_SUCCESS = "forget_password.success";

const FORGET_PASSWORD_FEEDBACK_TITLE = "forget_password.feedback.title";
const FORGET_PASSWORD_FEEDBACK_DESCRIPTION_1 = "forget_password.feedback.description_1";
const FORGET_PASSWORD_FEEDBACK_DESCRIPTION_2 = "forget_password.feedback.description_2";
const FORGET_PASSWORD_FEEDBACK_BUTTON = "forget_password.feedback.button";

//RegisterPassword
const REGISTER_PASSWORD_TITLE = "register_password.title";
const REGISTER_PASSWORD_NEW_PASSWORD = "register_password.new_password.name";
const REGISTER_PASSWORD_REQUIRED = "register_password.new_password.required";
const REGISTER_PASSWORD_REPEAT_NEW_PASSWORD = "register_password.repeat_new_password.name";
const REGISTER_PASSWORD_REPEAT_NEW_PASSWORD_VALIDATION = "register_password.repeat_new_password.validation";
const REGISTER_PASSWORD_SUBMIT = "register_password.submit";
const REGISTER_PASSWORD_SUCCESS = "register_password.success";
const REGISTER_PASSWORD_ERROR = "register_password.error";

// Header
const HEADER_TITLE = "header.title";

// Messages
const SUCCESSFUL_OPERATION = "messages.successful_operation";
const SUCCESSFULLY_LOGGED = "messages.successfully_logged";
const UNAVAILABLE_SERVICE = "messages.unavailable_service";
const NEED_SUPPORT = "messages.need_support";
const NOT_FOUND = "messages.not_found";
const BAD_REQUEST = "messages.bad_request";
const UNAUTHORIZED = "messages.unauthorized";
const FORBIDDEN = "messages.forbidden";
const INTERNAL_SERVER_ERROR = "messages.internal_server_error";
const ACCESS_DENIED = "messages.access_denied";
const NETWORK_ERROR = "messages.network_error";
const RESOURCE_NOT_FOUND = "messages.resource_not_found";
const METHOD_NOT_ALLOWED = "messages.method_not_allowed";
const SESSION_EXPIRED = "messages.session_expired";

// Unauthorized page
const UNAUTHORIZED_LABEL_UNAUTHORIZED_PAGE = "unauthorized.message";
const UNAUTHORIZED_LABEL_MENU = "unauthorized.link";
const UNAUTHORIZED_LABEL_OOPS = "unauthorized.oops";

const MENU_LOGOUT = "menu.logout";
const MENU_PRE_REGISTER = "menu.pre_register";
const MENU_MY_ACCOUNT = "menu.my_account";

// Confirm Dialog
const DIALOG_CONFIRM_YES = "dialog.yes";
const DIALOG_CONFIRM_OK = "dialog.ok";
const DIALOG_CONFIRM_CANCEL = "dialog.cancel";
const DIALOG_CONFIRM_ACCEPT = "dialog.accept";
const DIALOG_CONFIRM_SAVE = "dialog.save";
const DIALOG_CONFIRM_CLOSE = "dialog.close";

//User Term
const USER_TERM_TITLE = "user_tem.title";
const USER_TERM_DESCRIPTION = "user_tem.description";

//Pre register
const PRE_REGISTER_TITLE = "pre_register.title";
const PRE_REGISTER_FORM_NAME = "pre_register.form.name.value";
const PRE_REGISTER_FORM_EMAIL = "pre_register.form.email.value";
const PRE_REGISTER_FORM_USERNAME = "pre_register.form.username.value";
const PRE_REGISTER_FORM_PHONE = "pre_register.form.phone.value";
const PRE_REGISTER_FORM_POSITION = "pre_register.form.position.value";
const PRE_REGISTER_FORM_SECTOR = "pre_register.form.sector.value";
const PRE_REGISTER_FORM_PERMISSION = "pre_register.form.permission.value";
const PRE_REGISTER_FORM_CNPJ = "pre_register.form.cnpj.value";
const PRE_REGISTER_FORM_CNPJ_NO_DATA = "pre_register.form.cnpj.no_data";
const PRE_REGISTER_FORM_ACCEPT_PRIVACY_1 = "pre_register.form.accept_privacy.value_1";
const PRE_REGISTER_FORM_ACCEPT_PRIVACY_2 = "pre_register.form.accept_privacy.value_2";
const PRE_REGISTER_FORM_SUBMIT = "pre_register.form.submit";
const PRE_REGISTER_FORM_CANCEL = "pre_register.form.cancel";
const PRE_REGISTER_FORM_REQUIRED = "pre_register.form.validation.required";
const PRE_REGISTER_FORM_PERMISSION_MISSING = "pre_register.form.permission.missing_select";
const PRE_REGISTER_FORM_SEND_SUCCESS = "pre_register.form.success";
const PRE_REGISTER_FORM_EMAIL_WRONG_FORMAT = "pre_register.form.email.wrong_format";
const PRE_REGISTER_FORM_MIN_3 = "pre_register.form.validation.min_3";
const PRE_REGISTER_FORM_MIN_10 = "pre_register.form.validation.min_10";
const PRE_REGISTER_FORM_MAX_11 = "pre_register.form.validation.max_11";
const PRE_REGISTER_FORM_MAX_50 = "pre_register.form.validation.max_50";
const PRE_REGISTER_FORM_MAX_30 = "pre_register.form.validation.max_30";
const PRE_REGISTER_FORM_MAX_20 = "pre_register.form.validation.max_20";
const PRE_REGISTER_FORM_MAX_120 = "pre_register.form.validation.max_120";

//menu new user
const MENU_NEW_USER_TITLE = "menu_new_user.title";
const MENU_NEW_USER_DESCRIPTION = "menu_new_user.description";
const MENU_NEW_USER_SUPPLIER = "menu_new_user.supplier";
const MENU_NEW_USER_COLLABORATOR = "menu_new_user.collaborator";
const MENU_NEW_USER_ADMIN = "menu_new_user.admin";

//Form new user
const FORM_NEW_USER_PROFILE = "form_new_user.profile";
const FORM_NEW_USER_NAME = "form_new_user.name";
const FORM_NEW_USER_USERNAME = "form_new_user.username";
const FORM_NEW_USER_EMAIL = "form_new_user.email";
const FORM_NEW_USER_SEND_EMAIL = "form_new_user.sendEmail";
const FORM_NEW_USER_PHONE = "form_new_user.phone";
const FORM_NEW_USER_POSITION = "form_new_user.position";
const FORM_NEW_USER_PERMISSION = "form_new_user.permission";
const FORM_NEW_USER_NO_DATA = "form_new_user.noData";
const FORM_NEW_USER_SUPPLIERS = "form_new_user.suppliers";
const FORM_NEW_USER_RADIO_SUPPLIERS = "form_new_user.radio_supplier";
const FORM_NEW_USER_SUBMIT = "form_new_user.submit";
const FORM_NEW_USER_CANCEL = "form_new_user.cancel";
const FORM_NEW_USER_SETOR = "form_new_user.setor";
const FORM_NEW_USER_TITLE = "form_new_user.title";
const FORM_NEW_USER_SUCCESS = "form_new_user.success";

//Validation
const GENERAL_VALIDATION_REQUIRED = "general_validation.required";
const GENERAL_VALIDATION_MIN_3 = "general_validation.min_3";
const GENERAL_VALIDATION_MIN_10 = "general_validation.min_10";
const GENERAL_VALIDATION_MAX_11 = "general_validation.max_11";
const GENERAL_VALIDATION_MAX_50 = "general_validation.max_50";
const GENERAL_VALIDATION_MAX_30 = "general_validation.max_30";
const GENERAL_VALIDATION_MAX_20 = "general_validation.max_20";
const GENERAL_VALIDATION_MAX_120 = "general_validation.max_120";

const EMAIL_REQUIRED = "validation.email_required";
const NAME_REQUIRED = "validation.name_required";
const USERNAME_REQUIRED = "validation.username_required";
const USERNAME_WHITESPACE = "validation.username_whitespace";
const PHONE_REQUIRED = "validation.phone_required";
const SUPPLIER_REQUIRED = "validation.supplier_required";
const SECTOR_REQUIRED = "validation.sector_required";
const PERMISSION_REQUIRED = "validation.permission_required";
const POSITION_REQUIRED = "validation.position_required";

//Edit new user
const EDIT_NEW_USER_TITLE = "form_edit_new_user.title";
const EDIT_NEW_USER_PASSWORD = "form_edit_new_user.password";
const EDIT_NEW_USER_STATUS = "form_edit_new_user.status";
const EDIT_NEW_USER_ACTIVE = "form_edit_new_user.active";
const EDIT_NEW_USER_INACTIVE = "form_edit_new_user.inactive";
const EDIT_NEW_USER_SUCCESS = "form_edit_new_user.success";

//Aprove new user
const APROVE_USER_TITLE = "aprove_user.title";
const APROVE_USER_SUBMIT = "aprove_user.submit";
const APROVE_USER_REJECT = "aprove_user.reject";
const APROVE_USER_SUCCESS = "aprove_user.success";
const APROVE_USER_APPROVED = "aprove_user.approved";
const APROVE_USER_DIALOG_TITLE = "aprove_user.dialog.title";
const APROVE_USER_DIALOG_SUBMIT = "aprove_user.dialog.submit";
const APROVE_USER_DIALOG_CANCEL = "aprove_user.dialog.cancel";
const APROVE_USER_DIALOG_DESCRIPTION = "aprove_user.dialog.description";
const APROVE_USER_DIALOG_SUCCESS = "aprove_user.dialog.success";

//My Account
const MY_ACCOUNT_TITLE = "my_account.title";
const MY_ACCOUNT_SUCCESS = "my_account.success";
const MY_ACCOUNT_CONFIRM_PASSWORD = "my_account.confirm_password";
const MY_ACCOUNT_PASSWORD = "my_account.password";

//Users
const USERS_TITLE = "users.title";
const USERS_SEARCH_PLACEHOLDER = "users.search_placeholder";
const USERS_NEW_USER = "users.new_user";
const USERS_IMPORT_USER_TITLE = "users.importUser.title";
const USERS_IMPORT_USER_SUCCESS = "users.importUser.success";
const USERS_IMPORT_USER_ERROR = "users.importUser.error";
const USERS_IMPORT_USER_UPLOAD = "users.importUser.upload";
const USERS_IMPORT_USER_FAILED_UPLOAD_TITLE = "users.importUser.titleFailedUpload";

//Portal
const PORTAL_TEXT_1 = "portal.text_1";
const PORTAL_TEXT_2_1 = "portal.text_2_1";
const PORTAL_TEXT_2_2 = "portal.text_2_2";
const PORTAL_TEXT_2_3 = "portal.text_2_3";
const PORTAL_TEXT_2_4 = "portal.text_2_4";
const PORTAL_TEXT_2_5 = "portal.text_2_5";
const PORTAL_DOG_ALT = "portal.dog_alt";

//Modal Portal
const MODAL_CLOSE = "portal.modal.close";
const MODAL_TITLE = "portal.modal.title";
const MODAL_CONTENT = "portal.modal.content";

//Dialog Supplier
const DIALOG_SUPPLIER_TITLE_CONTRACTS = "dialog_supplier.title_contracts";
const DIALOG_SUPPLIER_TITLE_PORTAL = "dialog_supplier.title_portal";
const DIALOG_SUPPLIER_CANCEL = "dialog_supplier.cancel";
const DIALOG_SUPPLIER_CLEAN = "dialog_supplier.clean";

//Contracts
const CONTRACTS_TITLE = "contracts.title";
const CONTRACTS_CHANGE_SUPPLIER = "contracts.change_supplier";
const CONTRACTS_CATEGORY = "contracts.category";
const CONTRACTS_TYPE = "contracts.type";
const CONTRACTS_STATUS = "contracts.status";
const CONTRACTS_PLACEHOLDER = "contracts.placeholder";
const CONTRACTS_CLEAN = "contracts.clean";
const CONTRACTS_TERM_TITLE = "contracts.term_tile";
const CONTRACTS_TERM_DESCRIPTION = "contracts.term_description";
const CONTRACTS_PRICING_MARGIN_TITLE = "contracts.pricing_margin_title";
const CONTRACTS_PRICING_MARGIN_DESCRIPTION = "contracts.pricing_margin_description";
const CONTRACTS_PAYMENT_TERM_TITLE = "contracts.payment_term_title";
const CONTRACTS_PAYMENT_TERM_DESCRIPTION = "contracts.payment_term_description";
const CONTRACTS_PROMOTION_TITLE = "contracts.promotion_title";
const CONTRACTS_PROMOTION_DESCRIPTION = "contracts.promotion_description";
const CONTRACTS_NEW_ITEMS_TITLE = "contracts.new_items_title";
const CONTRACTS_NEW_ITEMS_DESCRIPTION = "contracts.new_items_description";
const CONTRACTS_OBSERVATIONS = "contracts.observations";
const CONTRACTS_EMPTY_OBSERVATIONS = "contracts.empty_observations";
const CONTRACTS_RECOMPOSITION_TITLE = "contracts.recomposition_title";
const CONTRACTS_RECOMPOSITION_DESC = "contracts.recomposition_description";

//Rubrics

const RUBRICS_TITLE = "rubrics.title";
const RUBRICS_NOTES = "rubrics.notes";
const RUBRICS_FILL_RATE = "rubrics.fill_rate";
const RUBRICS_REBATE = "rubrics.rebate";

//Receipt Dialog
const RECEIPT_DIALOG_TITLE = "receipt_dialog.title";
const RECEIPT_DIALOG_FORM_UPLOAD = "receipt_dialog.form.upload.value";
const RECEIPT_DIALOG_FORM_UPLOAD_REQUIRED = "receipt_dialog.form.upload.validation";
const RECEIPT_DIALOG_TEXT_SUM = "receipt_dialog.text_sum";
const RECEIPT_DIALOG_BUTTON = "receipt_dialog.button";
const RECEIPT_DIALOG_SUCCESS = "receipt_dialog.success";
const RECEIPT_DIALOG_ERROR = "receipt_dialog.error";

//Invoices
const INVOICES_TITLE = "invoices.title";
const INVOICES_OPEN = "invoices.open";
const INVOICES_BNF = "invoices.BNF";
const INVOICES_NDF = "invoices.NDF";
const INVOICES_NF_SETTLED = "invoices.nf_settled";
const INVOICES_BNF_SETTLED = "invoices.bnf_settle";
const INVOICES_PLACEHOLDER = "invoices.placeholder";
const INVOICES_RECEIPT_DIALOG_TITLE = "invoices.receipt_dialog_title";
const INVOICES_INFO_RECEIPT = "invoices.infoReceipt";
const INVOICES_DIALOG_CONFIRMATION = "invoices.dialog_confirmation";

//PeriodPicker
const PERIOD_PICKER_INIT_DATE = "period_picker.init_date";
const PERIOD_PICKER_END_DATE = "period_picker.end_date";

//DataTable
const DATATABLE_BODY_NO_MATCH = "datatable.body.noMatch";
const DATATABLE_BODY_TOOL_TIP = "datatable.body.toolTip";
const DATATABLE_BODY_COLUMN_HEADER_TOOLTIP = "datatable.body.columnHeaderTooltip";
const DATATABLE_PAGINATION_NEXT = "datatable.pagination.next";
const DATATABLE_PAGINATION_PREVIOUS = "datatable.pagination.previous";
const DATATABLE_PAGINATION_ROWS_PER_PAGE = "datatable.pagination.rowsPerPage";
const DATATABLE_PAGINATION_DISPLAY_ROWS = "datatable.pagination.displayRows";
const DATATABLE_CUSTOM_FOOTER_IMPORT_BUTTON = "datatable.customFooter.importButton";
const DATATABLE_CUSTOM_FOOTER_EXPORT_BUTTON = "datatable.customFooter.exportButton";
const DATATABLE_CUSTOM_FOOTER_EXPORT_CONSOLIDATED_BUTTON = "datatable.customFooter.exportConsolidatedButton";
const DATATABLE_CUSTOM_FOOTER_REPORT_BUTTON = "datatable.customFooter.reportButton";
const DATATABLE_CUSTOM_FOOTER_RECEIPT_BUTTON = "datatable.customFooter.receiptButton";
const DATATABLE_CUSTOM_FOOTER_INACTIVATE_BUTTON = "datatable.customFooter.inactivateButton";
const DATATABLE_CUSTOM_FOOTER_ACCEPT_BUTTON = "datatable.customFooter.acceptButton";
const DATATABLE_CUSTOM_FOOTER_REJECT_BUTTON = "datatable.customFooter.rejectButton";
const DATATABLE_CUSTOM_FOOTER_SIMULATE_BUTTON = "datatable.customFooter.simulateButton";
const DATATABLE_CUSTOM_FOOTER_IMPORT_MODEL_BUTTON = "datatable.customFooter.importModelButton";

//Anticipations
const ANTICIPATIONS_TITLE = "anticipations.title";
const ANTICIPATIONS_OPEN = "anticipations.open";
const ANTICIPATIONS_REQUESTED = "anticipations.requested";
const ANTICIPATIONS_ANTICIPATED = "anticipations.anticipated";
const ANTICIPATIONS_PURPLE_COUNT_TITLE = "anticipations.purpleCountTitle";
const ANTICIPATIONS_ORANGE_COUNT_TITLE = "anticipations.orangeCountTitle";
const ANTICIPATIONS_SEARCH_PLACEHOLDER = "anticipations.searchPlaceholder";
const ANTICIPATIONS_CLEAR = "anticipations.clear";
const ANTICIPATIONS_REPORT_BUTTON = "anticipations.reportButton";
const ANTICIPATIONS_SIMULATE_BUTTON = "anticipations.simulateButton";
const ANTICIPATIONS_DIALOG_TEXT = "anticipations.dialogText";
const ANTICIPATIONS_DIALOG_TEXT_2 = "anticipations.dialogText2";
const ANTICIPATIONS_DIALOG_TITLE = "anticipations.dialogTitle";

const ANTICIPATED_DIALOG_TITLE = "anticipated.dialogTitle";
const ANTICIPATED_DIALOG_TEXT = "anticipated.dialogText";

//Change Supplier Button
const CHANGE_SUPPLIER_BUTTON = "changeSupplierButton.tooltipTitle";

//Export
const EXPORT_NO_DATA_SELECT = "export.noDataSelect";
const EXPORT_ERROR = "export.error";

// Readjustments
const NEW_READJUSTMENT_TITLE = "readjustments.new.title";
const NEW_READJUSTMENT_FILTER_TITLE = "readjustments.new.filter.title";
const NEW_READJUSTMENT_FILTER_CNPJ_PLACEHOLDER = "readjustments.new.filter.cnpjPlaceholder";
const NEW_READJUSTMENT_FILTER_STATE_PLACEHOLDER = "readjustments.new.filter.statePlaceholder";
const NEW_READJUSTMENT_FILTER_CATEGORY_PLACEHOLDER = "readjustments.new.filter.categoryPlaceholder";
const NEW_READJUSTMENT_FILTER_SUBGROUP_PLACEHOLDER = "readjustments.new.filter.subgroupPlaceholder";
const NEW_READJUSTMENT_FILTER_SEARCH = "readjustments.new.filter.search";
const NEW_READJUSTMENT_FILTER_CLEAR = "readjustments.new.filter.clear";
const NEW_READJUSTMENT_FILTER_NO_CATEGORIES = "readjustments.new.filter.noCategories";
const NEW_READJUSTMENT_CNPJ_REQUIRED = "readjustments.new.filter.cnpjRequired";
const NEW_READJUSTMENT_STATE_REQUIRED = "readjustments.new.filter.stateRequired";
const NEW_READJUSTMENT_CATEGORY_REQUIRED = "readjustments.new.filter.categoryRequired";
const NEW_READJUSTMENT_SUBGROUP_REQUIRED = "readjustments.new.filter.subgroupRequired";
const NEW_READJUSTMENT_NO_OPTIONS = "readjustments.new.filter.noOptions";
const NEW_READJUSTMENT_LOADING = "readjustments.new.filter.loading";
const NEW_READJUSTMENT_NO_PRODUCTS = "readjustments.new.noProducts";
const NEW_READJUSTMENT_QUERY_PRODUCTS = "readjustments.new.queryProducts";
const NEW_READJUSTMENT_NO_PRODUCTS_SELECTED = "readjustments.new.noProductsSelected";
const NEW_READJUSTMENT_PROCEED = "readjustments.new.proceed";
const NEW_READJUSTMENT_CREATED = "readjustments.new.created";
const NEW_READJUSTMENT_BLOCKED_PRODUCTS_TITLE = "readjustments.new.blockedProducts.title";
const NEW_READJUSTMENT_BLOCKED_PRODUCTS_DIALOG_TITLE = "readjustments.new.blockedProducts.dialogTitle";
const NEW_READJUSTMENT_BLOCKED_PRODUCTS_MESSAGE = "readjustments.new.blockedProducts.message";
const NEW_READJUSTMENT_BLOCKED_PRODUCTS_ACTION = "readjustments.new.blockedProducts.action";
const NEW_READJUSTMENT_BLOCKED_PRODUCTS_CLOSE = "readjustments.new.blockedProducts.close";
const NEW_READJUSTMENT_DUPLICATED_INFO = "readjustments.new.duplicatedInfo";

const NEW_READJUSTMENT_RESULT_SIZE = "readjustments.new.resultSize";
const NEW_READJUSTMENT_DESCRIPTION = "readjustments.new.description";
const NEW_READJUSTMENT_PETZ_CODE = "readjustments.new.petzCode";
const NEW_READJUSTMENT_SUPP_CODE = "readjustments.new.suppCode";
const NEW_READJUSTMENT_BAR_CODE = "readjustments.new.barCode";
const NEW_READJUSTMENT_GROUP = "readjustments.new.group";
const NEW_READJUSTMENT_SUBGROUP = "readjustments.new.subgroup";
const NEW_READJUSTMENT_VIEW_MORE = "readjustments.new.viewMore";

// Edit readjustment
const EDIT_READJUSTMENT_TITLE = "readjustments.edit.title";
const EDIT_READJUSTMENT_EXPORT = "readjustments.edit.export";
const EDIT_READJUSTMENT_IMPORT = "readjustments.edit.import";
const EDIT_READJUSTMENT_COMMENT = "readjustments.edit.comment";
const EDIT_READJUSTMENT_CANCEL = "readjustments.edit.cancel";
const EDIT_READJUSTMENT_DELETE = "readjustments.edit.delete";
const EDIT_READJUSTMENT_NEGOTIATION_CANCEL_TITLE = "readjustments.edit.cancelNegotiationTitle";
const EDIT_READJUSTMENT_NEGOTIATION_CANCEL_TITLE_PLACEHOLDER = "readjustments.edit.cancelNegotiationTitlePlaceholder";
const EDIT_READJUSTMENT_NEGOTIATION_CANCEL_CONTENT = "readjustments.edit.cancelNegotiationContent";
const EDIT_READJUSTMENT_NEGOTIATION_CANCEL_CONFIRM = "readjustments.edit.cancelNegotiationConfirm";
const EDIT_READJUSTMENT_NEGOTIATION_CANCEL_CONFIRM_PLACEHOLDER =
	"readjustments.edit.cancelNegotiationConfirmPlaceholder";
const EDIT_READJUSTMENT_NEGOTIATION_CANCEL_SUCCESS = "readjustments.edit.cancelNegotiationSuccess";
const EDIT_READJUSTMENT_NEGOTIATION_CANCEL_PREFIX = "readjustments.edit.cancelNegotiationPrefix";
const EDIT_READJUSTMENT_NEGOTIATION_CANCEL_SUFIX = "readjustments.edit.cancelNegotiationSufix";

const EDIT_READJUSTMENT_NEGOTIATION_CANCEL_ERROR = "readjustments.edit.cancelNegotiationError";
const EDIT_READJUSTMENT_NEGOTIATION_DELETE_TITLE = "readjustments.edit.deleteNegotiationTitle";
const EDIT_READJUSTMENT_NEGOTIATION_DELETE_CONTENT = "readjustments.edit.deleteNegotiationContent";
const EDIT_READJUSTMENT_NEGOTIATION_DELETE_CONFIRM = "readjustments.edit.deleteNegotiationConfirm";
const EDIT_READJUSTMENT_NEGOTIATION_DELETE_SUCCESS = "readjustments.edit.deleteNegotiationSuccess";
const EDIT_READJUSTMENT_NEGOTIATION_DELETE_ERROR = "readjustments.edit.deleteNegotiationError";

const EDIT_READJUSTMENT_SAVE = "readjustments.edit.save";
const EDIT_READJUSTMENT_ACCEPT = "readjustments.edit.accept";
const EDIT_READJUSTMENT_REFUSE = "readjustments.edit.refuse";
const EDIT_READJUSTMENT_EDIT = "readjustments.edit.edit";
const EDIT_READJUSTMENT_SAVE_SUCCESS = "readjustments.edit.saveSuccess";
const EDIT_READJUSTMENT_SAVE_ERROR = "readjustments.edit.saveError";
const EDIT_READJUSTMENT_SEND = "readjustments.edit.send";
const EDIT_READJUSTMENT_QUERY = "readjustments.edit.query";
const EDIT_READJUSTMENT_CONFIRM_IMPORT = "readjustments.edit.confirmImport";
const EDIT_READJUSTMENT_CONFIRM_IMPORT_TITLE = "readjustments.edit.confirmImportTitle";
const EDIT_READJUSTMENT_CONFIRM_IMPORT_SUCCESS = "readjustments.edit.confirmImportSuccess";
const EDIT_READJUSTMENT_SWITCH_LABEL = "readjustments.edit.errorSwitchLabel";

const EDIT_READJUSTMENT_DESCRIPTION = "readjustments.edit.table.description";
const EDIT_READJUSTMENT_PETZ_CODE = "readjustments.edit.table.petzCode";
const EDIT_READJUSTMENT_SUPP_CODE = "readjustments.edit.table.suppCode";
const EDIT_READJUSTMENT_BAR_CODE = "readjustments.edit.table.barCode";
const EDIT_READJUSTMENT_CATEGORY = "readjustments.edit.table.category";
const EDIT_READJUSTMENT_SUBGROUP = "readjustments.edit.table.subgroup";
const EDIT_READJUSTMENT_TYPE = "readjustments.edit.table.type";
const EDIT_READJUSTMENT_DELETE_TITLE = "readjustments.edit.table.delete.title";
const EDIT_READJUSTMENT_DELETE_CONTENT = "readjustments.edit.table.delete.content";
const EDIT_READJUSTMENT_DELETE_CONFIRM = "readjustments.edit.table.delete.confirm";
const EDIT_READJUSTMENT_DELETE_CANCEL = "readjustments.edit.table.delete.cancel";
const EDIT_READJUSTMENT_DELETE_SUCCESS = "readjustments.edit.table.delete.success";
const EDIT_READJUSTMENT_DELETE_ERROR = "readjustments.edit.table.delete.error";

const EDIT_READJUSTMENT_STATE = "readjustments.edit.form.state";
const EDIT_READJUSTMENT_PROPOSAL = "readjustments.edit.form.proposal";
const EDIT_READJUSTMENT_COUNTERPROPOSAL = "readjustments.edit.form.counterproposal";
const EDIT_READJUSTMENT_COUNTERPROPOSAL_SUPP = "readjustments.edit.form.counterproposalSupp";
const EDIT_READJUSTMENT_CNPJ = "readjustments.edit.form.cnpj";
const EDIT_READJUSTMENT_COST_NF = "readjustments.edit.form.cost";
const EDIT_READJUSTMENT_NEW_COST_NF = "readjustments.edit.form.newCost";
const EDIT_READJUSTMENT_READJ = "readjustments.edit.form.readjustment";
const EDIT_READJUSTMENT_SUGGESTION = "readjustments.edit.form.sugestion";
const EDIT_READJUSTMENT_BUTTON = "readjustments.edit.form.button";
const EDIT_READJUSTMENT_BUTTON_READONLY = "readjustments.edit.form.buttonReadOnly";

const EDIT_READJUSTMENT_ERROR_CONTAINER_TITLE = "readjustments.edit.errorContainerTitle";
const EDIT_READJUSTMENT_ERROR_SUBMIT = "readjustments.edit.errorOnSubmit";

const EDIT_READJUSTMENT_DIALOG_TITLE = "readjustments.edit.dialog.title";
const EDIT_READJUSTMENT_DIALOG_CLEAR = "readjustments.edit.dialog.clear";
const EDIT_READJUSTMENT_DIALOG_SEARCH = "readjustments.edit.dialog.search";
const EDIT_READJUSTMENT_DIALOG_SAVE = "readjustments.edit.dialog.save";

const SEND_READJUSTMENT_DATE_STEP = "readjustments.edit.sendDialog.dateStep";
const SEND_READJUSTMENT_COMMENT_STEP = "readjustments.edit.sendDialog.commentStep";
const SEND_READJUSTMENT_CONFIRM_STEP = "readjustments.edit.sendDialog.confirmStep";
const SEND_READJUSTMENT_PREVIOUS_STEP = "readjustments.edit.sendDialog.previous";
const SEND_READJUSTMENT_NEXT_STEP = "readjustments.edit.sendDialog.next";

const SEND_READJUSTMENT_PROPOSAL_PLACEHOLDER = "readjustments.edit.sendDialog.placeholder";
const SEND_READJUSTMENT_PROPOSAL_LABEL = "readjustments.edit.sendDialog.label";
const SEND_READJUSTMENT_PROPOSAL_TITLE = "readjustments.edit.sendDialog.title";
const SEND_READJUSTMENT_COUNTERPROPOSAL_TITLE = "readjustments.edit.sendDialog.titleCounterproposal";
const SEND_READJUSTMENT_DATE_QUESTION = "readjustments.edit.sendDialog.dateQuestion";
const SEND_READJUSTMENT_DATE_ALERT = "readjustments.edit.sendDialog.dateAlert";
const SEND_READJUSTMENT_DATE_ALERT_TITLE = "readjustments.edit.sendDialog.dateAlertTitle";
const SEND_READJUSTMENT_COST_DATE = "readjustments.edit.sendDialog.costDate";
const SEND_READJUSTMENT_PV_DATE = "readjustments.edit.sendDialog.pvDate";
const SEND_READJUSTMENT_PASSED = "readjustments.edit.sendDialog.passed";

const SEND_READJUSTMENT_PROPOSAL_CONFIRM = "readjustments.edit.sendDialog.confirm";
const SEND_READJUSTMENT_PROPOSAL_CONFIRM_UPPER = "readjustments.edit.sendDialog.confirmUpper";
const EDIT_READJUSTMENT_SEND_PROPOSAL_CANCEL = "readjustments.edit.sendDialog.cancel";
const EDIT_READJUSTMENT_SEND_PROPOSAL_SUCCESS = "readjustments.edit.sendDialog.success";
const EDIT_READJUSTMENT_SEND_PROPOSAL_ERROR = "readjustments.edit.sendDialog.error";
const EDIT_READJUSTMENT_SEND_COUNTERPROPOSAL_SUCCESS = "readjustments.edit.sendDialog.successCounterproposal";
const EDIT_READJUSTMENT_SEND_COUNTERPROPOSAL_ERROR = "readjustments.edit.sendDialog.errorCounterproposal";

const EDIT_COUNTER_PROPOSAL_ACCEPT_TITLE = "readjustments.edit.counterProposal.acceptTitle";
const EDIT_COUNTER_PROPOSAL_ACCEPT_CONTENT = "readjustments.edit.counterProposal.acceptContent";
const EDIT_COUNTER_PROPOSAL_ACCEPT_ACTION = "readjustments.edit.counterProposal.acceptAction";
const EDIT_COUNTER_PROPOSAL_ACCEPT_SUCCESS = "readjustments.edit.counterProposal.acceptSuccess";
const EDIT_COUNTER_PROPOSAL_ACCEPT_ERROR = "readjustments.edit.counterProposal.acceptError";

const EDIT_COUNTER_PROPOSAL_REFUSE_TITLE = "readjustments.edit.counterProposal.refuseTitle";
const EDIT_COUNTER_PROPOSAL_REFUSE_CONTENT = "readjustments.edit.counterProposal.refuseContent";
const EDIT_COUNTER_PROPOSAL_REFUSE_ACTION = "readjustments.edit.counterProposal.refuseAction";
const EDIT_COUNTER_PROPOSAL_REFUSE_SUCCESS = "readjustments.edit.counterProposal.refuseSuccess";
const EDIT_COUNTER_PROPOSAL_REFUSE_ERROR = "readjustments.edit.counterProposal.refuseError";

const EDIT_COUNTER_PROPOSAL_EDIT_TITLE = "readjustments.edit.counterProposal.editTitle";
const EDIT_COUNTER_PROPOSAL_EDIT_CONTENT = "readjustments.edit.counterProposal.editContent";
const EDIT_COUNTER_PROPOSAL_EDIT_ACTION = "readjustments.edit.counterProposal.editAction";
const EDIT_COUNTER_PROPOSAL_EDIT_SUCCESS = "readjustments.edit.counterProposal.editSuccess";
const EDIT_COUNTER_PROPOSAL_EDIT_ERROR = "readjustments.edit.counterProposal.editError";

const VIEW_PROPOSAL_MESSAGES_TITLE = "readjustments.edit.messagesTitle";

const NEGOTIATION_SEARCH_TITLE = "readjustments.status.title";
const NEGOTIATION_SEARCH_SEND_DATE = "readjustments.status.sendDate";
const NEGOTIATION_SEARCH_NEGOTIATION_NUMBER = "readjustments.status.negotiationNumber";
const NEGOTIATION_SEARCH_NEGOTIATION_STATUS = "readjustments.status.status";
const NEGOTIATION_SEARCH_SUBMIT = "readjustments.status.filter.submit";
const NEGOTIATION_SEARCH_CLEAR = "readjustments.status.filter.clear";
const NEGOTIATION_SEARCH_NEW = "readjustments.status.filter.new";
const NEGOTIATION_SEARCH_VIEW = "readjustments.status.view";
const NEGOTIATION_SEARCH_CANCEL = "readjustments.status.cancel";
const NEGOTIATION_SEARCH_DELETE = "readjustments.status.delete";
const NEGOTIATION_SEARCH_FIND_PRODUCTS = "readjustments.status.findProducts";
const NEGOTIATION_SEARCH_RESULT_SIZE = "readjustments.status.resultSize";

export default {
	NO_DATA,
	NO_SEARCH_YET,
	SOON,
	PAGINATION,
	CURRENCY,
	LOCALE,
	LOGIN_TITLE,
	LOGIN_USER,
	LOGIN_USER_REQUIRED,
	LOGIN_PASSWORD,
	LOGIN_PASSWORD_REQUIRED,
	LOGIN_SUBMIT,
	LOGIN_ACCESS_DENIED,
	HEADER_TITLE,
	SUCCESSFUL_OPERATION,
	SUCCESSFULLY_LOGGED,
	UNAVAILABLE_SERVICE,
	NEED_SUPPORT,
	NOT_FOUND,
	BAD_REQUEST,
	UNAUTHORIZED,
	FORBIDDEN,
	INTERNAL_SERVER_ERROR,
	ACCESS_DENIED,
	NETWORK_ERROR,
	RESOURCE_NOT_FOUND,
	METHOD_NOT_ALLOWED,
	SESSION_EXPIRED,
	UNAUTHORIZED_LABEL_UNAUTHORIZED_PAGE,
	UNAUTHORIZED_LABEL_MENU,
	UNAUTHORIZED_LABEL_OOPS,
	MENU_LOGOUT,
	FORGET_PASSWORD_TITLE,
	FORGET_PASSWORD_EMAIL,
	FORGET_PASSWORD_EMAIL_REQUIRED,
	FORGET_PASSWORD_SEND_BUTTON,
	FORGET_PASSWORD_FEEDBACK_TITLE,
	FORGET_PASSWORD_FEEDBACK_DESCRIPTION_1,
	FORGET_PASSWORD_FEEDBACK_DESCRIPTION_2,
	FORGET_PASSWORD_FEEDBACK_BUTTON,
	FORGET_PASSWORD_SUCCESS,
	LOGIN_FORGET_PASSWORD,
	REGISTER_PASSWORD_TITLE,
	REGISTER_PASSWORD_NEW_PASSWORD,
	REGISTER_PASSWORD_REPEAT_NEW_PASSWORD,
	REGISTER_PASSWORD_REQUIRED,
	REGISTER_PASSWORD_REPEAT_NEW_PASSWORD_VALIDATION,
	REGISTER_PASSWORD_SUBMIT,
	REGISTER_PASSWORD_SUCCESS,
	REGISTER_PASSWORD_ERROR,
	DIALOG_CONFIRM_YES,
	DIALOG_CONFIRM_OK,
	DIALOG_CONFIRM_CANCEL,
	DIALOG_CONFIRM_ACCEPT,
	USER_TERM_TITLE,
	USER_TERM_DESCRIPTION,
	PRE_REGISTER_TITLE,
	PRE_REGISTER_FORM_NAME,
	PRE_REGISTER_FORM_EMAIL,
	PRE_REGISTER_FORM_USERNAME,
	PRE_REGISTER_FORM_PHONE,
	PRE_REGISTER_FORM_POSITION,
	PRE_REGISTER_FORM_SECTOR,
	PRE_REGISTER_FORM_PERMISSION,
	PRE_REGISTER_FORM_CNPJ,
	PRE_REGISTER_FORM_CNPJ_NO_DATA,
	PRE_REGISTER_FORM_ACCEPT_PRIVACY_1,
	PRE_REGISTER_FORM_ACCEPT_PRIVACY_2,
	PRE_REGISTER_FORM_SUBMIT,
	PRE_REGISTER_FORM_CANCEL,
	PRE_REGISTER_FORM_REQUIRED,
	PRE_REGISTER_FORM_PERMISSION_MISSING,
	MENU_PRE_REGISTER,
	PRE_REGISTER_FORM_SEND_SUCCESS,
	PRE_REGISTER_FORM_EMAIL_WRONG_FORMAT,
	PRE_REGISTER_FORM_MIN_3,
	PRE_REGISTER_FORM_MIN_10,
	PRE_REGISTER_FORM_MAX_50,
	PRE_REGISTER_FORM_MAX_30,
	PRE_REGISTER_FORM_MAX_20,
	PRE_REGISTER_FORM_MAX_120,
	PRE_REGISTER_FORM_MAX_11,
	MENU_NEW_USER_TITLE,
	MENU_NEW_USER_DESCRIPTION,
	MENU_NEW_USER_SUPPLIER,
	MENU_NEW_USER_COLLABORATOR,
	MENU_NEW_USER_ADMIN,
	FORM_NEW_USER_PROFILE,
	FORM_NEW_USER_NAME,
	FORM_NEW_USER_USERNAME,
	FORM_NEW_USER_EMAIL,
	FORM_NEW_USER_SEND_EMAIL,
	FORM_NEW_USER_PHONE,
	FORM_NEW_USER_POSITION,
	FORM_NEW_USER_PERMISSION,
	FORM_NEW_USER_NO_DATA,
	FORM_NEW_USER_SUPPLIERS,
	FORM_NEW_USER_SUBMIT,
	FORM_NEW_USER_CANCEL,
	FORM_NEW_USER_RADIO_SUPPLIERS,
	FORM_NEW_USER_SETOR,
	FORM_NEW_USER_TITLE,
	FORM_NEW_USER_SUCCESS,
	GENERAL_VALIDATION_MIN_3,
	GENERAL_VALIDATION_MIN_10,
	GENERAL_VALIDATION_MAX_11,
	GENERAL_VALIDATION_MAX_50,
	GENERAL_VALIDATION_MAX_30,
	GENERAL_VALIDATION_MAX_20,
	GENERAL_VALIDATION_MAX_120,
	GENERAL_VALIDATION_REQUIRED,
	EDIT_NEW_USER_TITLE,
	EDIT_NEW_USER_PASSWORD,
	EDIT_NEW_USER_STATUS,
	EDIT_NEW_USER_ACTIVE,
	EDIT_NEW_USER_INACTIVE,
	APROVE_USER_TITLE,
	EDIT_NEW_USER_SUCCESS,
	APROVE_USER_SUBMIT,
	APROVE_USER_REJECT,
	APROVE_USER_DIALOG_TITLE,
	APROVE_USER_DIALOG_SUBMIT,
	APROVE_USER_DIALOG_CANCEL,
	APROVE_USER_DIALOG_DESCRIPTION,
	APROVE_USER_DIALOG_SUCCESS,
	APROVE_USER_SUCCESS,
	APROVE_USER_APPROVED,
	MY_ACCOUNT_TITLE,
	MY_ACCOUNT_SUCCESS,
	MY_ACCOUNT_CONFIRM_PASSWORD,
	MY_ACCOUNT_PASSWORD,
	MENU_MY_ACCOUNT,
	USERS_TITLE,
	USERS_SEARCH_PLACEHOLDER,
	USERS_NEW_USER,
	PORTAL_TEXT_1,
	PORTAL_TEXT_2_1,
	PORTAL_TEXT_2_2,
	PORTAL_DOG_ALT,
	MODAL_CLOSE,
	MODAL_TITLE,
	MODAL_CONTENT,
	LOGIN_FORGET_REMEMBER_ME,
	DIALOG_CONFIRM_SAVE,
	DIALOG_CONFIRM_CLOSE,
	DIALOG_SUPPLIER_TITLE_CONTRACTS,
	DIALOG_SUPPLIER_TITLE_PORTAL,
	DIALOG_SUPPLIER_CANCEL,
	DIALOG_SUPPLIER_CLEAN,
	EMAIL_REQUIRED,
	NAME_REQUIRED,
	USERNAME_REQUIRED,
	PHONE_REQUIRED,
	SUPPLIER_REQUIRED,
	SECTOR_REQUIRED,
	PERMISSION_REQUIRED,
	POSITION_REQUIRED,
	CONTRACTS_TITLE,
	CONTRACTS_CHANGE_SUPPLIER,
	CONTRACTS_CATEGORY,
	CONTRACTS_TYPE,
	CONTRACTS_STATUS,
	CONTRACTS_PLACEHOLDER,
	CONTRACTS_CLEAN,
	RUBRICS_TITLE,
	RUBRICS_NOTES,
	RUBRICS_FILL_RATE,
	RUBRICS_REBATE,
	RECEIPT_DIALOG_TITLE,
	RECEIPT_DIALOG_FORM_UPLOAD,
	RECEIPT_DIALOG_FORM_UPLOAD_REQUIRED,
	RECEIPT_DIALOG_TEXT_SUM,
	RECEIPT_DIALOG_BUTTON,
	RECEIPT_DIALOG_SUCCESS,
	RECEIPT_DIALOG_ERROR,
	INVOICES_TITLE,
	INVOICES_OPEN,
	INVOICES_BNF,
	INVOICES_NDF,
	INVOICES_NF_SETTLED,
	INVOICES_BNF_SETTLED,
	INVOICES_PLACEHOLDER,
	USERNAME_WHITESPACE,
	PERIOD_PICKER_INIT_DATE,
	PERIOD_PICKER_END_DATE,
	INVOICES_RECEIPT_DIALOG_TITLE,
	DATATABLE_BODY_NO_MATCH,
	DATATABLE_BODY_TOOL_TIP,
	DATATABLE_BODY_COLUMN_HEADER_TOOLTIP,
	DATATABLE_PAGINATION_NEXT,
	DATATABLE_PAGINATION_PREVIOUS,
	DATATABLE_PAGINATION_ROWS_PER_PAGE,
	DATATABLE_PAGINATION_DISPLAY_ROWS,
	DATATABLE_CUSTOM_FOOTER_IMPORT_BUTTON,
	DATATABLE_CUSTOM_FOOTER_EXPORT_BUTTON,
	DATATABLE_CUSTOM_FOOTER_EXPORT_CONSOLIDATED_BUTTON,
	DATATABLE_CUSTOM_FOOTER_REPORT_BUTTON,
	DATATABLE_CUSTOM_FOOTER_RECEIPT_BUTTON,
	DATATABLE_CUSTOM_FOOTER_INACTIVATE_BUTTON,
	INVOICES_INFO_RECEIPT,
	PORTAL_TEXT_2_3,
	PORTAL_TEXT_2_4,
	PORTAL_TEXT_2_5,
	ANTICIPATIONS_OPEN,
	ANTICIPATIONS_REQUESTED,
	ANTICIPATIONS_ANTICIPATED,
	ANTICIPATIONS_TITLE,
	ANTICIPATIONS_PURPLE_COUNT_TITLE,
	ANTICIPATIONS_ORANGE_COUNT_TITLE,
	ANTICIPATIONS_SEARCH_PLACEHOLDER,
	ANTICIPATIONS_CLEAR,
	DATATABLE_CUSTOM_FOOTER_ACCEPT_BUTTON,
	DATATABLE_CUSTOM_FOOTER_REJECT_BUTTON,
	ANTICIPATIONS_REPORT_BUTTON,
	ANTICIPATIONS_DIALOG_TEXT,
	ANTICIPATIONS_SIMULATE_BUTTON,
	DATATABLE_CUSTOM_FOOTER_SIMULATE_BUTTON,
	ANTICIPATIONS_DIALOG_TITLE,
	ANTICIPATIONS_DIALOG_TEXT_2,
	ANTICIPATED_DIALOG_TEXT,
	ANTICIPATED_DIALOG_TITLE,
	CHANGE_SUPPLIER_BUTTON,
	DATATABLE_CUSTOM_FOOTER_IMPORT_MODEL_BUTTON,
	USERS_IMPORT_USER_TITLE,
	USERS_IMPORT_USER_SUCCESS,
	USERS_IMPORT_USER_ERROR,
	USERS_IMPORT_USER_UPLOAD,
	USERS_IMPORT_USER_FAILED_UPLOAD_TITLE,
	EXPORT_NO_DATA_SELECT,
	EXPORT_ERROR,
	INVOICES_DIALOG_CONFIRMATION,
	CONTRACTS_TERM_TITLE,
	CONTRACTS_TERM_DESCRIPTION,
	CONTRACTS_PRICING_MARGIN_TITLE,
	CONTRACTS_PRICING_MARGIN_DESCRIPTION,
	CONTRACTS_PAYMENT_TERM_TITLE,
	CONTRACTS_PAYMENT_TERM_DESCRIPTION,
	CONTRACTS_PROMOTION_TITLE,
	CONTRACTS_PROMOTION_DESCRIPTION,
	CONTRACTS_NEW_ITEMS_TITLE,
	CONTRACTS_NEW_ITEMS_DESCRIPTION,
	CONTRACTS_OBSERVATIONS,
	CONTRACTS_EMPTY_OBSERVATIONS,
	CONTRACTS_RECOMPOSITION_TITLE,
	CONTRACTS_RECOMPOSITION_DESC,
	NEW_READJUSTMENT_TITLE,
	NEW_READJUSTMENT_FILTER_TITLE,
	NEW_READJUSTMENT_FILTER_CNPJ_PLACEHOLDER,
	NEW_READJUSTMENT_FILTER_STATE_PLACEHOLDER,
	NEW_READJUSTMENT_FILTER_CATEGORY_PLACEHOLDER,
	NEW_READJUSTMENT_FILTER_SUBGROUP_PLACEHOLDER,
	NEW_READJUSTMENT_FILTER_NO_CATEGORIES,
	NEW_READJUSTMENT_FILTER_SEARCH,
	NEW_READJUSTMENT_FILTER_CLEAR,
	NEW_READJUSTMENT_CNPJ_REQUIRED,
	NEW_READJUSTMENT_STATE_REQUIRED,
	NEW_READJUSTMENT_CATEGORY_REQUIRED,
	NEW_READJUSTMENT_SUBGROUP_REQUIRED,
	NEW_READJUSTMENT_NO_PRODUCTS,
	NEW_READJUSTMENT_QUERY_PRODUCTS,
	NEW_READJUSTMENT_NO_PRODUCTS_SELECTED,
	NEW_READJUSTMENT_PROCEED,
	NEW_READJUSTMENT_CREATED,
	NEW_READJUSTMENT_NO_OPTIONS,
	NEW_READJUSTMENT_LOADING,
	NEW_READJUSTMENT_BLOCKED_PRODUCTS_TITLE,
	NEW_READJUSTMENT_BLOCKED_PRODUCTS_DIALOG_TITLE,
	NEW_READJUSTMENT_BLOCKED_PRODUCTS_MESSAGE,
	NEW_READJUSTMENT_BLOCKED_PRODUCTS_ACTION,
	NEW_READJUSTMENT_BLOCKED_PRODUCTS_CLOSE,
	NEW_READJUSTMENT_DUPLICATED_INFO,
	NEW_READJUSTMENT_DESCRIPTION,
	NEW_READJUSTMENT_RESULT_SIZE,
	NEW_READJUSTMENT_PETZ_CODE,
	NEW_READJUSTMENT_SUPP_CODE,
	NEW_READJUSTMENT_BAR_CODE,
	NEW_READJUSTMENT_GROUP,
	NEW_READJUSTMENT_SUBGROUP,
	NEW_READJUSTMENT_VIEW_MORE,
	EDIT_READJUSTMENT_TITLE,
	EDIT_READJUSTMENT_EXPORT,
	EDIT_READJUSTMENT_IMPORT,
	EDIT_READJUSTMENT_CONFIRM_IMPORT,
	EDIT_READJUSTMENT_CONFIRM_IMPORT_TITLE,
	EDIT_READJUSTMENT_CONFIRM_IMPORT_SUCCESS,
	EDIT_READJUSTMENT_COMMENT,
	EDIT_READJUSTMENT_CANCEL,
	EDIT_READJUSTMENT_DELETE,
	EDIT_READJUSTMENT_NEGOTIATION_CANCEL_TITLE,
	EDIT_READJUSTMENT_NEGOTIATION_CANCEL_TITLE_PLACEHOLDER,
	EDIT_READJUSTMENT_NEGOTIATION_CANCEL_CONTENT,
	EDIT_READJUSTMENT_NEGOTIATION_CANCEL_CONFIRM,
	EDIT_READJUSTMENT_NEGOTIATION_CANCEL_CONFIRM_PLACEHOLDER,
	EDIT_READJUSTMENT_NEGOTIATION_CANCEL_SUCCESS,
	EDIT_READJUSTMENT_NEGOTIATION_CANCEL_PREFIX,
	EDIT_READJUSTMENT_NEGOTIATION_CANCEL_SUFIX,
	EDIT_READJUSTMENT_NEGOTIATION_CANCEL_ERROR,
	EDIT_READJUSTMENT_NEGOTIATION_DELETE_TITLE,
	EDIT_READJUSTMENT_NEGOTIATION_DELETE_CONTENT,
	EDIT_READJUSTMENT_NEGOTIATION_DELETE_CONFIRM,
	EDIT_READJUSTMENT_NEGOTIATION_DELETE_SUCCESS,
	EDIT_READJUSTMENT_NEGOTIATION_DELETE_ERROR,
	EDIT_READJUSTMENT_SAVE,
	EDIT_READJUSTMENT_SAVE_SUCCESS,
	EDIT_READJUSTMENT_SAVE_ERROR,
	EDIT_READJUSTMENT_SEND,
	EDIT_READJUSTMENT_QUERY,
	EDIT_READJUSTMENT_DESCRIPTION,
	EDIT_READJUSTMENT_PETZ_CODE,
	EDIT_READJUSTMENT_SUPP_CODE,
	EDIT_READJUSTMENT_BAR_CODE,
	EDIT_READJUSTMENT_CATEGORY,
	EDIT_READJUSTMENT_SUBGROUP,
	EDIT_READJUSTMENT_TYPE,
	EDIT_READJUSTMENT_STATE,
	EDIT_READJUSTMENT_CNPJ,
	EDIT_READJUSTMENT_COST_NF,
	EDIT_READJUSTMENT_NEW_COST_NF,
	EDIT_READJUSTMENT_READJ,
	EDIT_READJUSTMENT_SUGGESTION,
	EDIT_READJUSTMENT_BUTTON,
	EDIT_READJUSTMENT_BUTTON_READONLY,
	EDIT_READJUSTMENT_DIALOG_TITLE,
	EDIT_READJUSTMENT_DIALOG_CLEAR,
	EDIT_READJUSTMENT_DIALOG_SEARCH,
	EDIT_READJUSTMENT_DIALOG_SAVE,
	EDIT_READJUSTMENT_DELETE_TITLE,
	EDIT_READJUSTMENT_DELETE_CONTENT,
	EDIT_READJUSTMENT_DELETE_CONFIRM,
	EDIT_READJUSTMENT_DELETE_CANCEL,
	EDIT_READJUSTMENT_DELETE_SUCCESS,
	EDIT_READJUSTMENT_DELETE_ERROR,
	EDIT_READJUSTMENT_ERROR_CONTAINER_TITLE,
	EDIT_READJUSTMENT_SWITCH_LABEL,
	EDIT_READJUSTMENT_SEND_PROPOSAL_CANCEL,
	EDIT_READJUSTMENT_SEND_PROPOSAL_SUCCESS,
	EDIT_READJUSTMENT_SEND_PROPOSAL_ERROR,
	EDIT_READJUSTMENT_ERROR_SUBMIT,
	SEND_READJUSTMENT_PROPOSAL_CONFIRM,
	SEND_READJUSTMENT_PROPOSAL_CONFIRM_UPPER,
	SEND_READJUSTMENT_PROPOSAL_PLACEHOLDER,
	SEND_READJUSTMENT_PROPOSAL_LABEL,
	SEND_READJUSTMENT_DATE_STEP,
	SEND_READJUSTMENT_COMMENT_STEP,
	SEND_READJUSTMENT_CONFIRM_STEP,
	SEND_READJUSTMENT_PREVIOUS_STEP,
	SEND_READJUSTMENT_NEXT_STEP,
	SEND_READJUSTMENT_COST_DATE,
	SEND_READJUSTMENT_PV_DATE,
	SEND_READJUSTMENT_PASSED,
	SEND_READJUSTMENT_PROPOSAL_TITLE,
	SEND_READJUSTMENT_COUNTERPROPOSAL_TITLE,
	SEND_READJUSTMENT_DATE_QUESTION,
	SEND_READJUSTMENT_DATE_ALERT,
	SEND_READJUSTMENT_DATE_ALERT_TITLE,
	EDIT_READJUSTMENT_ACCEPT,
	EDIT_READJUSTMENT_REFUSE,
	EDIT_READJUSTMENT_EDIT,
	EDIT_COUNTER_PROPOSAL_ACCEPT_TITLE,
	EDIT_COUNTER_PROPOSAL_ACCEPT_CONTENT,
	EDIT_COUNTER_PROPOSAL_ACCEPT_ACTION,
	EDIT_COUNTER_PROPOSAL_ACCEPT_SUCCESS,
	EDIT_COUNTER_PROPOSAL_ACCEPT_ERROR,
	EDIT_COUNTER_PROPOSAL_REFUSE_TITLE,
	EDIT_COUNTER_PROPOSAL_REFUSE_CONTENT,
	EDIT_COUNTER_PROPOSAL_REFUSE_ACTION,
	EDIT_COUNTER_PROPOSAL_REFUSE_SUCCESS,
	EDIT_COUNTER_PROPOSAL_REFUSE_ERROR,
	EDIT_COUNTER_PROPOSAL_EDIT_TITLE,
	EDIT_COUNTER_PROPOSAL_EDIT_CONTENT,
	EDIT_COUNTER_PROPOSAL_EDIT_ACTION,
	EDIT_COUNTER_PROPOSAL_EDIT_SUCCESS,
	EDIT_COUNTER_PROPOSAL_EDIT_ERROR,
	EDIT_READJUSTMENT_PROPOSAL,
	EDIT_READJUSTMENT_COUNTERPROPOSAL,
	EDIT_READJUSTMENT_COUNTERPROPOSAL_SUPP,
	EDIT_READJUSTMENT_SEND_COUNTERPROPOSAL_SUCCESS,
	EDIT_READJUSTMENT_SEND_COUNTERPROPOSAL_ERROR,
	VIEW_PROPOSAL_MESSAGES_TITLE,
	NEGOTIATION_SEARCH_TITLE,
	NEGOTIATION_SEARCH_SEND_DATE,
	NEGOTIATION_SEARCH_NEGOTIATION_NUMBER,
	NEGOTIATION_SEARCH_NEGOTIATION_STATUS,
	NEGOTIATION_SEARCH_SUBMIT,
	NEGOTIATION_SEARCH_CLEAR,
	NEGOTIATION_SEARCH_NEW,
	NEGOTIATION_SEARCH_VIEW,
	NEGOTIATION_SEARCH_CANCEL,
	NEGOTIATION_SEARCH_DELETE,
	NEGOTIATION_SEARCH_FIND_PRODUCTS,
	NEGOTIATION_SEARCH_RESULT_SIZE,
};
