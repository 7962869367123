import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	ThemeProvider,
	Tooltip,
	Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import supplierAction from "~/actions/supplierAction";
import FormikAutocompleteGroup from "~/components/common/autocomplete/FormikAutocompleteCheckbox";
import Labels from "~/helpers/enums/Labels";
import useDebounce from "~/helpers/useDebounce";
import Utils from "~/helpers/Utils";
import MuiStylesDialogSupplier from "./MuiStylesDialogSupplier";
import MuiThemeDialogSupplier from "./MuiThemeDialogSupplier";

const DialogSupplier = (props) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const classes = MuiStylesDialogSupplier();
	const locationContracts = props.location.pathname === "/contratos" ? true : false;

	const {
		suppliers,
		dialog: { open, fnRoute },
		filter,
	} = useSelector((state) => state.supplier);

	const [searchTerm, setSearchTerm] = useState("");
	const [isSearching, setIsSearching] = useState(false);
	const debouncedSearchTerm = useDebounce(searchTerm, 1200);

	useEffect(() => {
		if (open) {
			dispatch(supplierAction.clearSuppliers());
		}
	}, [open, dispatch]);
	useEffect(() => {
		if (debouncedSearchTerm) {
			setIsSearching(true);
			dispatch(
				supplierAction.getSuppliers({ text: debouncedSearchTerm }, (error) => {
					setIsSearching(false);
					if (error) {
						Utils.showError(error);
					}
				})
			);
		}
	}, [debouncedSearchTerm, dispatch]);

	const handleClose = () => {
		dispatch(supplierAction.closeSupplierDialog());
	};

	return (
		<ThemeProvider theme={MuiThemeDialogSupplier}>
			<Dialog fullWidth onClose={handleClose} open={open}>
				<Formik
					initialValues={{
						optionGroup: {},
						suppliers: filter.suppliers || [],
					}}
					validate={(values) => {
						const errors = {};

						if (values.suppliers.length < 1) {
							errors.suppliers = t(Labels.GENERAL_VALIDATION_REQUIRED);
						}

						return errors;
					}}
					onSubmit={(values, { setSubmitting, resetForm }) => {
						// userAction.sendReject(filter, props.userId, (error) => {
						setSubmitting(false);
						// 	if (!error) {
						dispatch(supplierAction.saveSupplierFilter({ suppliers: values.suppliers }));
						resetForm();
						handleClose();
						if(fnRoute)
							fnRoute(values.suppliers);
							
						// 	} else {
						// 		Utils.showError(error.message);
						// 	}
						// })
					}}>
					{({ submitForm, isSubmitting, values, setValues }) => (
						<Form>
							<DialogTitle
								className={clsx({
									[classes.dialogSupplierTitleContracts]: locationContracts,
									[classes.dialogSupplierTitlePortal]: !locationContracts,
								})}>
								{locationContracts ? t(Labels.DIALOG_SUPPLIER_TITLE_CONTRACTS) : t(Labels.DIALOG_SUPPLIER_TITLE_PORTAL)}
							</DialogTitle>
							<DialogContent className={classes.contentDialog}>
								<Grid container direction="row" justify="center" alignItems="center" spacing={2}>
									<Grid item xs={12}>
										<Field
											id="suppliers"
											name="suppliers"
											multiple
											disabled={isSubmitting}
											helper={{
												optionGroup: values.optionGroup,
												showCheckbox: true,
												markedItens: values.suppliers,
												label: "company",
												labelFormat: (option) => {
													const label = `${option.id} - ${option.store} - ${option.company}` || "";
													return (
														<Tooltip title={label} aria-label={label}>
															<Typography
																style={{
																	textOverflow: "ellipsis",
																	whiteSpace: "nowrap",
																	overflow: "hidden",
																	width: "480px",
																}}>
																{label}
															</Typography>
														</Tooltip>
													);
												},
												groupBy: "groupDescription",
												disabledCheckboxBy: "hasGroup",
											}}
											component={FormikAutocompleteGroup}
											options={suppliers}
											groupBy={(option) => option.groupDescription}
											getOptionLabel={(option) => `${option.id} - ${option.store} - ${option.company}` || ""}
											getOptionSelected={(option, value) => option.id === value.id}
											filterOptions={(options, _) => options}
											noOptionsText={t(Labels.FORM_NEW_USER_NO_DATA)}
											disableCloseOnSelect={true}
											disableClearable={true}
											loading={isSearching}
											textFieldProps={{
												fullWidth: true,
												margin: "dense",
												variant: "outlined",
												label: t(Labels.FORM_NEW_USER_SUPPLIERS),
												onChange: (event) => {
													const value = event.target.value;
													if (value.length > 2) setSearchTerm(value);
												},
											}}
										/>
									</Grid>
								</Grid>
							</DialogContent>
							<DialogContent className={classes.footerDialog}>
								<Button
									id="cancel-button"
									disabled={isSubmitting}
									color="primary"
									variant="outlined"
									onClick={handleClose}
									className={clsx(classes.button, classes.cancelButton)}>
									{t(Labels.DIALOG_SUPPLIER_CANCEL)}
								</Button>

								<Button
									id="submit-button"
									disabled={isSubmitting}
									onClick={submitForm}
									color="primary"
									variant="contained"
									className={clsx(classes.button, classes.saveButton)}>
									{t(Labels.DIALOG_CONFIRM_SAVE)}
								</Button>

								<Button
									id="clean-button"
									disabled={isSubmitting}
									color="primary"
									variant="outlined"
									onClick={() => {
										setValues({
											optionGroup: {},
											suppliers: [],
										});
									}}
									className={clsx(classes.button, classes.cancelButton)}>
									{t(Labels.DIALOG_SUPPLIER_CLEAN)}
								</Button>
							</DialogContent>
						</Form>
					)}
				</Formik>
			</Dialog>
		</ThemeProvider>
	);
};

export default withRouter(DialogSupplier);
