import { makeStyles } from "@material-ui/core/styles";

const MuiStylesCustomDialog = makeStyles((theme) => ({
	detailsTitle: {
		color: "#A3B1D4",
		fontSize: theme.spacing(2.625),
	},
}));

export default MuiStylesCustomDialog;
