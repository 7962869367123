import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ListSubheader from "@material-ui/core/ListSubheader";
import { useTheme } from "@material-ui/core/styles";
import { VariableSizeList } from "react-window";

const LISTBOX_PADDING = 8;

function renderRow(props) {
	const { data, index, style } = props;
	return React.cloneElement(data[index], {
		style: {
			...style,
			top: style.top + LISTBOX_PADDING,
		},
	});
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
	const outerProps = React.useContext(OuterElementContext);
	return <div ref={ref} {...props} {...outerProps} />;
});

const InnerElementType = React.forwardRef(({ style, ...props }, ref) => {
	return <ul id="list" style={{ ...style, width: "auto" }} ref={ref} {...props} />;
});

function useResetCache(data) {
	const ref = React.useRef(null);
	React.useEffect(() => {
		if (ref.current != null) {
			ref.current.resetAfterIndex(0, true);
		}
	}, [data]);
	return ref;
}

const Listbox = React.forwardRef(function ListboxComponent(props, ref) {
	const { children, ...other } = props;
	const itemData = React.Children.toArray(children);
	const theme = useTheme();
	const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
	const itemCount = itemData.length;
	const itemSize = smUp ? 36 : 48;

	const getChildSize = (child) => {
		if (React.isValidElement(child) && child.type === ListSubheader) {
			return 48;
		}

		return itemSize;
	};

	const getHeight = () => {
		if (itemCount > 8) {
			return 8 * itemSize;
		}
		return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
	};

	const gridRef = useResetCache(itemCount);
	return (
		<div ref={ref}>
			<OuterElementContext.Provider value={other}>
				<VariableSizeList
					itemData={itemData}
					height={getHeight() + 2 * LISTBOX_PADDING}
					width="auto"
					ref={gridRef}
					outerElementType={OuterElementType}
					innerElementType={InnerElementType}
					itemSize={(index) => getChildSize(itemData[index])}
					overscanCount={10}
					itemCount={itemCount}>
					{renderRow}
				</VariableSizeList>
			</OuterElementContext.Provider>
		</div>
	);
});

export default Listbox;
