import dayjs from "dayjs";
import i18next from "i18next";
import jwtDecode from "jwt-decode";
import _ from "lodash";
import { toast } from "react-toastify";
import VMasker from "vanilla-masker";
import Constants from "~/helpers/enums/Constants";
import { ContractStatus, UserStatus } from "./enums/Statuses";
import debounce from "lodash.debounce";

export const not = (obj) => !obj;

export const isEmpty = (obj) => _.isEmpty(obj);

export const isNotEmpty = (obj) => !isEmpty(obj);

export const isEmptyOrNullOrZero = (obj) => {
	return isEmpty(obj) || obj === 0;
};

export const setToken = (token, key = Constants.TOKEN) => {
	localStorage.setItem(key, token);
};

export const getToken = (key = Constants.TOKEN) => {
	return localStorage.getItem(key);
};

const tokenExpTime = (token) => {
	if (isEmpty(token.exp)) return true;

	return token.exp > Math.floor(new Date().getTime() / 1000);
};

export const hasTokenValid = () => {
	const token = getDataToken();
	return isNotEmpty(token) && tokenExpTime(token);
};

export const removeToken = (key = Constants.TOKEN) => {
	return localStorage.removeItem(key);
};

export const startLoading = (identifier) => ({
	type: Constants.UPDATE_LOADING,
	loading: { [identifier]: true },
});

export const endLoading = (identifier) => ({
	type: Constants.UPDATE_LOADING,
	loading: { [identifier]: false },
});

export const showToast = ({ description, type, ...props }) => {
	toast(description, {
		type: type,
		position: toast.POSITION.TOP_CENTER,
		...props,
	});
};

export const showTranslatedToast = ({ description, type }, props = () => {}) => {
	if (!description) return;
	try {
		const Translated = i18next.t(description);
		description = Translated;
	} catch (error) {
	} finally {
		toast(description, {
			type: type,
			position: toast.POSITION.TOP_CENTER,
			...props,
		});
	}
};

export const showError = debounce((message) => {
	showTranslatedToast({
		type: Constants.ERROR,
		description: message,
	});
}, 1000);

export const showSuccess = (message) => {
	showTranslatedToast({
		type: Constants.SUCCESS,
		description: message,
	});
};

export const showInfo = (message) => {
	showTranslatedToast({
		type: Constants.INFO,
		description: message,
	});
};

export const showAllErrors = (errors) => {
	const show = (errors) => {
		errors &&
			errors.forEach((e) => {
				showError(e);
			});
	};

	if (errors.response.data.errors) {
		show(Object.values(errors.response.data.errors).reduce((a, b) => a.concat(b)));
	} else if (errors.response.data) {
		showError(errors.response.data.error);
	}
};

export const getDataToken = () => {
	const token = getToken();
	if (token) {
		return jwtDecode(token);
	}
	return null;
};

export const checkUserProfile = (requestProfile) => {
	if (isEmptyOrNullOrZero(requestProfile)) return true;
	const token = getDataToken() || { role: [] };
	const role = token.functionalities || [];
	const authorities = token.authorities || [];

	return requestProfile.some((request) => role.includes(request) || authorities.includes(request));
};

export const getName = (name) => {
	return name.split(/(\s).+\s/).join("");
};

export const lowerAndCapitalize = (string) => {
	let word = string.toLowerCase();
	return word.charAt(0).toUpperCase() + word.slice(1);
};

// export const cryptAES = (values, key) => {
// 	return Crypto.AES.encrypt(values, key).toString();
// };

export const formatMoneyValue = (string) => {
	let value = parseFloat(string).toFixed(2);
	const negativeSign = value < 0 ? "-" : "";
	const formatted = VMasker.toMoney(value);

	return negativeSign + formatted;
};

export const formatPercentage = (string) => {
	let value = parseFloat(string);
	value = value * 100;
	return VMasker.toMoney(value.toFixed(2));
};

export const hasProperty = (data, property) => {
	return data.some((value) => !isEmpty(`${value[property]}`.trim()));
};
export const getUserStatusObject = (string) => {
	const statusList = UserStatus;
	const object = statusList.find((item) => string.toLowerCase() === item.description.toLowerCase());
	// eslint-disable-next-line eqeqeq
	return object === {} || object == undefined ? null : object;
};

export const formatCnpj = (value) => {
	return value?.replace(/\D/g, "").replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5");
};

export const formatCpf = (value) => {
	return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
};

export const getContractStatusObject = (string) => {
	const contractStatusList = ContractStatus;
	const object = contractStatusList.find((item) => string.toLowerCase().includes(item.description.toLowerCase()));
	// eslint-disable-next-line eqeqeq
	return object === {} || object == undefined ? null : object;
};

export const formatContract = (contract) => {
	let contractNumber = Number(contract.contractNumber).toString();
	let revision = Number(contract.revision).toString();
	return `${contractNumber}/${revision}`;
};

export const formatDate = (date, type, format) => {
	if (date === "    -  -  T00:00:00") {
		return "";
	}
	return type === "end" ? ` - ${dayjs(date).format("DD/MM/YYYY")}` : dayjs(date).format("DD/MM/YYYY");
};

export const lightenContracts = (array) => {
	return array.map((item) => {
		let i = {
			contractNumber: Number(item.contract.contractNumber).toString(),
			company: item.contactSupplier.company,
			contractNumberFormatted: formatContract(item.contract),
			categoryDescription: item.categoryDescription,
			typeDescription: item.type.typeDescription,
			contractDescription: item.contract.description,
			dates: formatDate(item.dates.contractDate, "init"),
			value: formatMoneyValue(item.value),
			statusDescription: lowerAndCapitalize(item.status.statusDescription),
		};

		return i;
	});
};

export const filterTableData = (search, data, convertedColumns) => {
	return data.filter((objectData) => {
		return Object.entries(convertedColumns).some(([attribute, objectColumn]) => {
			let isValid = false;
			if (objectColumn.notFilter) {
				return false;
			}
			const orginalValue = attribute.split(".").reduce((prev, item) => prev[item], objectData);
			const fnRnder = objectColumn.customBodyRender;
			if ((isNotEmpty(orginalValue) || typeof orginalValue === "number") && typeof fnRnder === "function") {
				const formatedOriginalValue = fnRnder(orginalValue)?.props?.children;
				// const formatedValue = fnRnder(search)?.props?.children;
				// isValid = `${formatedOriginalValue}`.includes(`${formatedValue}`) | `${orginalValue}`.includes(`${search}`) | `${orginalValue}`.includes(`${formatedValue}`) |`${formatedOriginalValue}`.includes(`${search}`);
				isValid = `${formatedOriginalValue}`.toLocaleLowerCase().includes(`${search}`.toLocaleLowerCase());
			}
			return isValid;
		});
	});
};

export const convertColumnsToObject = (name, array) => {
	return array.reduce((obj, item) => ({ ...obj, [item[name]]: { label: item.label, ...item.options } }), {});
};

export const lightenRebates = (array) => {
	return array.map((item) => {
		let i = {
			previousTrimesterMonthOne: convertDate(
				`${item.rebate.previousTrimester.monthOne}/${item.rebate.previousTrimester.year}`
			),
			previousTrimesterMonthTwo: convertDate(
				`${item.rebate.previousTrimester.monthTwo}/${item.rebate.previousTrimester.year}`
			),
			previousTrimesterMonthThree: convertDate(
				`${item.rebate.previousTrimester.monthThree}/${item.rebate.previousTrimester.year}`
			),
			previousTrimesterTotalMonthOne: formatMoneyValue(item.rebate.previousTrimester.totalMonthOne),
			previousTrimesterTotalMonthTwo: formatMoneyValue(item.rebate.previousTrimester.totalMonthTwo),
			previousTrimesterTotalMonthThree: formatMoneyValue(item.rebate.previousTrimester.totalMonthThree),
			previousTrimesterTotal: formatMoneyValue(item.rebate.previousTrimester.total),
			actualTrimesterMonthOne: convertDate(
				`${item.rebate.actualTrimester.monthOne}/${item.rebate.actualTrimester.year}`
			),
			actualTrimesterMonthTwo: convertDate(
				`${item.rebate.actualTrimester.monthTwo}/${item.rebate.actualTrimester.year}`
			),
			actualTrimesterMonthThree: convertDate(
				`${item.rebate.actualTrimester.monthThree}/${item.rebate.actualTrimester.year}`
			),
			actualTrimesterTotalMonthOne: formatMoneyValue(item.rebate.actualTrimester.totalMonthOne),
			actualTrimesterTotalMonthTwo: formatMoneyValue(item.rebate.actualTrimester.totalMonthTwo),
			actualTrimesterTotalMonthThree: formatMoneyValue(item.rebate.actualTrimester.totalMonthThree),
			actualTrimesterTotal: formatMoneyValue(item.rebate.actualTrimester.total),
			sellInTrimesterMonthOne: convertDate(
				`${item.rebate.sellInTrimester.monthOne}/${item.rebate.sellInTrimester.year}`
			),
			sellInTrimesterMonthTwo: convertDate(
				`${item.rebate.sellInTrimester.monthTwo}/${item.rebate.sellInTrimester.year}`
			),
			sellInTrimesterMonthThree: convertDate(
				`${item.rebate.sellInTrimester.monthThree}/${item.rebate.sellInTrimester.year}`
			),
			sellInTrimesterTotalMonthOne: formatMoneyValue(item.rebate.sellInTrimester.totalMonthOne),
			sellInTrimesterTotalMonthTwo: formatMoneyValue(item.rebate.sellInTrimester.totalMonthTwo),
			sellInTrimesterTotalMonthThree: formatMoneyValue(item.rebate.sellInTrimester.totalMonthThree),
			sellInTrimesterTotal: formatMoneyValue(item.rebate.sellInTrimester.total),
			cnpj: formatCnpj(item.rebate.cnpj),
			supplierName: item.rebate.supplierName,
			supplierId: item.rebate.supplierId,
			growthRate: formatPercentage(item.rebate.growthRate / 100),
			yearToYear: formatPercentage(item.rebate.yearToYear / 100),
			rebate: formatPercentage(item.rebate.percentage / 100),
			receivable: formatMoneyValue(item.rebate.value),
			contractNumber: formatContract({
				contractNumber: item.rebate.contractNumber,
				revision: item.rebate.contractRevision,
			}),
		};

		return i;
	});
};

export const removeWhiteSpacing = (data) => {
	if (data && typeof data === "string") return data.trimEnd();
	return data;
};

export const convertDate = (date, formatDate) => {
	let format = formatDate === undefined ? "MMM YYYY" : formatDate;
	let dateConverted = dayjs(date, "MM/YYYY").format(format);
	return dateConverted.charAt(0).toUpperCase() + dateConverted.slice(1);
};

const downloadBuffer = (data, filename, contentType = "xlsx", format = dayjs().format("YYYYMMDDHHmmss")) => {
	const blob = new Blob([data], { type: contentType });
	const element = document.createElement("a");
	const href = URL.createObjectURL(blob);

	element.setAttribute("href", href);
	element.setAttribute("target", "_blank");
	element.setAttribute("download", `${filename}_${format}.${contentType}`);
	element.style.display = "none";
	document.body.appendChild(element);

	element.click();
	document.body.removeChild(element);
};

const creatingExportParams = (tableData, convertedColumns, type) => {
	const columns = Object.entries(convertedColumns).filter(
		([key, value]) => typeof value.customBodyRender === "function"
	);
	const columnWithFormat = columns.filter(([attribute, objectColumn]) => objectColumn.excelFormatted);
	const header = columns
		.filter(([attribute, objectColumn]) => objectColumn.excelExport)
		.map(([attribute, objectColumn]) => ({ attribute, label: objectColumn.label, ...objectColumn.excel }));

	const items = tableData.map((data) => {
		const arrayDataFormatted = columnWithFormat.map(([attribute, objectColumn]) => ({
			[attribute]: objectColumn.customBodyRender(data[attribute])?.props?.children,
		}));
		const objDataFormatted = arrayDataFormatted.reduce((obj, item) => ({ ...obj, ...item }), {});
		return { ...data, ...objDataFormatted };
	});

	return { header, items, type };
};

function getTextWidth(text, font) {
	const canvas = document.createElement("canvas");
	const context = canvas.getContext("2d");

	context.font = font || getComputedStyle(document.body).font;

	return context.measureText(text).width;
}

export default {
	not,
	isEmpty,
	isNotEmpty,
	endLoading,
	startLoading,
	hasTokenValid,
	setToken,
	removeToken,
	getToken,
	showTranslatedToast,
	isEmptyOrNullOrZero,
	showError,
	showToast,
	showAllErrors,
	checkUserProfile,
	showSuccess,
	getName,
	lowerAndCapitalize,
	showInfo,
	hasProperty,
	formatCnpj,
	getUserStatusObject,
	getContractStatusObject,
	formatContract,
	formatDate,
	lightenContracts,
	filterTableData,
	convertColumnsToObject,
	removeWhiteSpacing,
	lightenRebates,
	convertDate,
	formatCpf,
	downloadBuffer,
	creatingExportParams,
	getTextWidth,
};
