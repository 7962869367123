import Constants from '~/helpers/enums/Constants';

const initialState = {
  isAuthenticated: false,
  user: null,
  forgetEmail: "",
  acceptanceTerm: true,
  isTemporaryPassword: "false"
};

function authReducer(state = initialState, action = null) {
  switch (action.type) {
    case Constants.LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        isTemporaryPassword: `${action.payload.isTemporaryPassword}`,
        acceptanceTerm: action.payload.acceptanceTerm
      };
    case Constants.LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    case Constants.FORGET_PASSWORD:
      return {
        ...state,
        forgetEmail: action.payload.email
      }
    case Constants.CLEAN_FORGET_PASSWORD: 
      return {
        ...state,
        forgetEmail: initialState.forgetEmail
      }
    case Constants.ACCEPT_TERM:
      return {
        ...state,
        acceptanceTerm: initialState.acceptanceTerm
      }
    case Constants.SET_TEMPORARY_PASSWORD_FALSE: 
      return {
        ...state,
        isTemporaryPassword: false
      }
    default:
      return state;
  }
}

export default authReducer;
