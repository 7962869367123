import Constants from "~/helpers/enums/Constants";

const INITIAL_STATE = {
	fillRateData: [],
	invoices: []
};

function rubricReducer(state = INITIAL_STATE, action = null) {
	switch (action.type) {
		case Constants.GET_FILL_RATE:
			return {
				...state,
				fillRateData: action.payload || [],
			};
		case Constants.GET_INVOICES:
			return {
				...state,
				invoices: action.payload || []
			}
		default:
			return state;
	}
}

export default rubricReducer;
