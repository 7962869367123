// Header PAGE
export const FETCH_LOGIN = "FETCH_LOGIN";
export const FALSE = "FALSE";
export const TRUE = "TRUE";

export const UPDATE_LOADING = "UPDATE_LOADING";
export const UPDATE_LOGIN = "UPDATE_LOGIN";

export const LOGOUT = "LOGOUT";
export const TOKEN = "TOKEN";
export const USER = "USER";
export const TOKEN_ACCEPT_TEM = "TOKEN_ACCEPT_TEM";
export const TOKEN_TEMPORARY_PASSWORD = "TOKEN_TEMPORARY_PASSWORD";
export const SET_TEMPORARY_PASSWORD_FALSE = "SET_TEMPORARY_PASSWORD_FALSE";
export const LOGIN = "LOGIN";

export const ACCEPT_TERM = "ACCEPT_TERM";

export const SET_BACKGROUND = "SET_BACKGROUND";

export const OPEN_REJECT_DIALOG = "OPEN_REJECT_DIALOG";
export const CLOSE_REJECT_DIALOG = "CLOSE_REJECT_DIALOG";

//ForgetPassword
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const CLEAN_FORGET_PASSWORD = "CLEAN_FORGET_PASSWORD";

// Messages types
export const DEFAULT = "default";
export const SUCCESS = "success";
export const ERROR = "error";
export const INFO = "info";
export const WARNING = "warning";

// Choose language
export const LANGUAGE = "LANGUAGE";

//Generic
export const OPEN_CONFIRM_DIALOG = "OPEN_CONFIRM_DIALOG";
export const CLOSE_CONFIRM_DIALOG = "CLOSE_CONFIRM_DIALOG";
export const OPEN_CUSTOM_DIALOG = "OPEN_CUSTOM_DIALOG";
export const CLOSE_CUSTOM_DIALOG = "CLOSE_CUSTOM_DIALOG";

//Functionality
export const GET_FUNCTIONALITY = "GET_FUNCTIONALITY";
export const GET_PROFILE_FUNCTIONALITIES = "GET_PROFILE_FUNCTIONALITIES";

//Sectors
export const GET_SECTOR = "GET_SECTOR";

//Supplier
export const GET_SUPPLIER = "GET_SUPPLIER";
export const CLEAR_SUPPLIER = "CLEAR_SUPPLIER";

//Position
export const GET_POSITION = "GET_POSITION";

//User
export const GET_USER = "GET_USER";
export const GET_OWN_DATA = "GET_OWN_DATA";
export const CLEAR_USER = "CLEAR_USER";
export const GET_OWN_SUPPLIER = "GET_OWN_SUPPLIER";

//Notifications
export const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS";
export const REFRESH_TIMEOUT = 180000;

//Contract
export const OPEN_SUPPLIER_DIALOG = "OPEN_SUPPLIER_DIALOG";
export const CLOSE_SUPPLIER_DIALOG = "CLOSE_SUPPLIER_DIALOG";
export const SAVES_SUPPLIER_FILTER = "SAVES_SUPPLIER_FILTER";
export const GET_CONTRACT_DETAIL = "GET_CONTRACT_DETAIL";

//Rubric
export const GET_FILL_RATE = "GET_FILL_RATE";
export const GET_INVOICES = "GET_INVOICES";

// Readjustment
export const SET_READJUSTMENT_CNPJS = "SET_READJUSTMENT_CNPJS";
export const SET_READJUSTMENT_STORE = "SET_READJUSTMENT_STORE";
export const SET_READJUSTMENT_STATES = "SET_READJUSTMENT_STATES";
export const SET_READJUSTMENT_GROUPS = "SET_READJUSTMENT_GROUPS";
export const SET_READJUSTMENT_SUBGROUPS = "SET_READJUSTMENT_SUBGROUPS";
export const SET_READJUSTMENT_HAS_SEARCHED = "SET_READJUSTMENT_HAS_SEARCHED";
export const CLEAR_READJUSTMENT_FILTER = "CLEAR_READJUSTMENT_FILTER";
export const CLEAR_READJUSTMENT_FILTER_DATA = "CLEAR_READJUSTMENT_FILTER_DATA";
export const SET_READJUSTMENT_CNPJS_LOADING = "SET_READJUSTMENT_CNPJS_LOADING";
export const SET_READJUSTMENT_GROUPS_LOADING = "SET_READJUSTMENT_GROUPS_LOADING";
export const SET_READJUSTMENT_PRODUCTS = "SET_READJUSTMENT_PRODUCTS";
export const SET_READJUSTMENT_PRODUCTS_LOADING = "SET_READJUSTMENT_PRODUCTS_LOADING";
export const SET_READJUSTMENT_MARKED_PRODUCTS = "SET_READJUSTMENT_MARKED_PRODUCTS";
export const SET_READJUSTMENT_PRODUCTS_PAGE = "SET_READJUSTMENT_PRODUCTS_PAGE";
export const SET_READJUSTMENT_PRODUCTS_MARKED = "SET_READJUSTMENT_PRODUCTS_MARKED";
export const SET_READJUSTMENT_SELECTED_STATES = "SET_READJUSTMENT_SELECTED_STATES";
export const SET_READJUSTMENT_SELECTED_CNPJS = "SET_READJUSTMENT_SELECTED_CNPJS";

export const SET_READJUSTMENT_STATUS_FILTER = "SET_READJUSTMENT_STATUS_FILTER";
export const CLEAR_READJUSTMENT_STATUS_FILTER = "CLEAR_READJUSTMENT_STATUS_FILTER";
export const SET_LOADING_STATUS_FILTER = "SET_LOADING_STATUS_FILTER";
export const SET_ERROR_STATUS_FILTER = "SET_ERROR_STATUS_FILTER";
export const SET_STATUS_FILTER_OUTDATED = "SET_STATUS_FILTER_OUTDATED";

export const RUBRIC_TYPES = {
	LOGISTICS: "01",
	BREAKDOWN_EXCHANGE: "02",
	SELL_IN: "03",
	REBATE: "04",
	FILL_RATE: "05",
	NEW_STORES: "06",
	RETROFIT: "07",
	TRADE_MARKETING: "09",
	VOLUME: "13",
	BIRTHDAY: "14",
	UNCONDITIONAL_BONIFICATION: "16",
	DATA_SHARING: "19",
	COST_CENTER: "20",
	RECOMPOSITION: "99",
};

export const COST_CENTER_TYPES = {
	SPONSORSHIP: "1",
	RECOMPOSITION: "2"
}

export default {
	FETCH_LOGIN,
	UPDATE_LOADING,
	UPDATE_LOGIN,
	LOGOUT,
	TOKEN,
	USER,
	SUCCESS,
	ERROR,
	DEFAULT,
	INFO,
	WARNING,
	FALSE,
	TRUE,
	LANGUAGE,
	LOGIN,
	OPEN_CONFIRM_DIALOG,
	CLOSE_CONFIRM_DIALOG,
	GET_FUNCTIONALITY,
	GET_SECTOR,
	FORGET_PASSWORD,
	CLEAN_FORGET_PASSWORD,
	ACCEPT_TERM,
	TOKEN_ACCEPT_TEM,
	TOKEN_TEMPORARY_PASSWORD,
	GET_SUPPLIER,
	SET_BACKGROUND,
	SET_TEMPORARY_PASSWORD_FALSE,
	GET_PROFILE_FUNCTIONALITIES,
	GET_USER,
	OPEN_REJECT_DIALOG,
	CLOSE_REJECT_DIALOG,
	GET_OWN_DATA,
	GET_POSITION,
	UPDATE_NOTIFICATIONS,
	REFRESH_TIMEOUT,
	OPEN_SUPPLIER_DIALOG,
	CLOSE_SUPPLIER_DIALOG,
	SAVES_SUPPLIER_FILTER,
	GET_CONTRACT_DETAIL,
	CLEAR_USER,
	GET_OWN_SUPPLIER,
	CLEAR_SUPPLIER,
	GET_FILL_RATE,
	GET_INVOICES,
	OPEN_CUSTOM_DIALOG,
	CLOSE_CUSTOM_DIALOG,
	SET_READJUSTMENT_CNPJS,
	SET_READJUSTMENT_STATES,
	SET_READJUSTMENT_STORE,
	SET_READJUSTMENT_GROUPS,
	SET_READJUSTMENT_SUBGROUPS,
	CLEAR_READJUSTMENT_FILTER,
	CLEAR_READJUSTMENT_FILTER_DATA,
	SET_READJUSTMENT_CNPJS_LOADING,
	SET_READJUSTMENT_GROUPS_LOADING,
	SET_READJUSTMENT_PRODUCTS,
	SET_READJUSTMENT_PRODUCTS_LOADING,
	SET_READJUSTMENT_MARKED_PRODUCTS,
	SET_READJUSTMENT_PRODUCTS_PAGE,
	SET_READJUSTMENT_HAS_SEARCHED,
	SET_READJUSTMENT_SELECTED_STATES,
	SET_READJUSTMENT_SELECTED_CNPJS,
	SET_READJUSTMENT_STATUS_FILTER,
	CLEAR_READJUSTMENT_STATUS_FILTER,
	SET_LOADING_STATUS_FILTER,
	SET_ERROR_STATUS_FILTER,
	SET_STATUS_FILTER_OUTDATED,
};
