import Constants from '~/helpers/enums/Constants';

const initialState = {
  sectors: [],
};

function sectorReducer(state = initialState, action = null) {
  switch (action.type) {
    case Constants.GET_SECTOR:
      return {
        ...state,
        sectors: action.payload,
      };
    default:
      return state;
  }
}

export default sectorReducer;
