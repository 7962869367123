import Constants from "~/helpers/enums/Constants";

export const INITIAL_STATUS_FILTER = {
	negotiations: [],
	outdated: false,
};

export const INITIAL_STATE = {
	isProductsLoading: false,
	hasSearched: false,
	markedProducts: [],
	selectedStates: [],
	selectedCnpjs: [],
	subgroups: [],
	blockedProducts: [],
	products: [],
	states: [],
	groups: [],
	cnpjs: [],
	page: 0,
	statusFilter: INITIAL_STATUS_FILTER,
};

function readjustmentReducer(state = INITIAL_STATE, action = null) {
	switch (action.type) {
		case Constants.SET_READJUSTMENT_CNPJS:
			return {
				...state,
				cnpjs: action.payload,
			};
		case Constants.SET_READJUSTMENT_STATES:
			return {
				...state,
				states: action.payload,
			};
		case Constants.SET_READJUSTMENT_GROUPS:
			return {
				...state,
				groups: action.payload,
			};
		case Constants.SET_READJUSTMENT_SUBGROUPS:
			return {
				...state,
				subgroups: action.payload,
			};
		case Constants.CLEAR_READJUSTMENT_FILTER:
			return INITIAL_STATE;
		case Constants.CLEAR_READJUSTMENT_FILTER_DATA:
			return {
				...INITIAL_STATE,
				cnpjs: state.cnpjs,
			};
		case Constants.SET_READJUSTMENT_STORE:
			return {
				...state,
				...action.payload,
			};
		case Constants.SET_READJUSTMENT_PRODUCTS:
			return {
				...state,
				blockedProducts: action.payload.blockedProducts,
				products: action.payload.products,
				page: 0,
			};
		case Constants.SET_READJUSTMENT_PRODUCTS_PAGE:
			return {
				...state,
				page: action.payload,
			};
		case Constants.SET_READJUSTMENT_MARKED_PRODUCTS:
			return {
				...state,
				markedProducts: action.payload,
			};
		case Constants.SET_READJUSTMENT_HAS_SEARCHED:
			return {
				...state,
				hasSearched: action.payload,
			};
		case Constants.SET_READJUSTMENT_SELECTED_STATES:
			return {
				...state,
				selectedStates: action.payload,
			};
		case Constants.SET_READJUSTMENT_SELECTED_CNPJS:
			return {
				...state,
				selectedCnpjs: action.payload,
			};
		case Constants.SET_READJUSTMENT_PRODUCTS_LOADING:
			return {
				...state,
				isProductsLoading: action.payload,
				hasSearched: true,
			};
		case Constants.SET_READJUSTMENT_STATUS_FILTER:
			return {
				...state,
				statusFilter: {
					...state.statusFilter,
					...action.payload,
					outdated: false,
				},
			};
		case Constants.CLEAR_READJUSTMENT_STATUS_FILTER:
			return {
				...state,
				statusFilter: INITIAL_STATUS_FILTER,
			};
		case Constants.SET_STATUS_FILTER_OUTDATED:
			return {
				...state,
				statusFilter: {
					...state.statusFilter,
					outdated: true,
				},
			};
		default:
			return state;
	}
}

export default readjustmentReducer;
