import http from "~/config/httpRequest/http";

const CONTROLLER_NAME = "api/auth";

export const login = (credentials) => {
	return new Promise((resolve, reject) => {
		http
			.post(`/${CONTROLLER_NAME}/login`, credentials)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

export const sendForgetPassword = (credentials) => {
	return new Promise((resolve, reject) => {
		http
			.post(`/${CONTROLLER_NAME}/forgot-password`, credentials)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

export const registerPassword = (credentials) => {
	return new Promise((resolve, reject) => {
		http
			.post(`/${CONTROLLER_NAME}/update-password`, credentials)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

export default { login, sendForgetPassword, registerPassword };
