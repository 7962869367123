import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";

const dayjsCustom = dayjs;

dayjsCustom.extend(relativeTime);
dayjsCustom.extend(localizedFormat);

export default dayjsCustom;
