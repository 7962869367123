import React from "react";
import LoadingOverlay from "react-loading-overlay";
import { LoadingGifIcon } from "../icons/Icons";

const DEFAULT_STYLES = {
	overlay: (base) => ({
		...base,
		background: "rgba(255,255,255,0.7)",
		zIndex: 99998,
		borderRadius: "6px",
	}),
};

const SpinnerLoading = (props) => {
	return (
		<LoadingOverlay
			active={props.loading}
			styles={props.styles ? props.styles : DEFAULT_STYLES}
			spinner={<LoadingGifIcon style={{ width: "100px" }} />}>
			{props.children}
		</LoadingOverlay>
	);
};

export default SpinnerLoading;
