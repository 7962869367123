import http from "~/config/httpRequest/http";

const CONTROLLER_NAME = "api/users";

export const registerPassword = (credentials) => {
	return new Promise((resolve, reject) => {
		http
			.put(`/${CONTROLLER_NAME}/update-password`, credentials)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

export const sendUserTem = () => {
	return new Promise((resolve, reject) => {
		http
			.post(`/${CONTROLLER_NAME}/accept-acceptance-term`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

export const sendPreRegister = (param) => {
	return new Promise((resolve, reject) => {
		http
			.post(`/${CONTROLLER_NAME}/pre-registration`, { ...param })
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

export const registerForm = (param, adress) => {
	return new Promise((resolve, reject) => {
		http
			.post(`/${CONTROLLER_NAME}/${adress}`, { ...param })
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

export const getUserById = (id) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/${CONTROLLER_NAME}/${id}`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

export const updateUser = (param, id) => {
	return new Promise((resolve, reject) => {
		http
			.put(`/${CONTROLLER_NAME}/${id}`, { ...param })
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

export const rejectUser = (param, id) => {
	return new Promise((resolve, reject) => {
		http
			.put(`/${CONTROLLER_NAME}/${id}/reject`, { ...param })
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

export const aproveUser = (param, id) => {
	return new Promise((resolve, reject) => {
		http
			.put(`/${CONTROLLER_NAME}/${id}/approve`, { ...param })
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

export const inactivateUsers = (param) => {
	return new Promise((resolve, reject) => {
		http
			.put(`/${CONTROLLER_NAME}/inactivate`, param)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

export const searchUsers = (params) => {
	return new Promise((resolve, reject) => {
		let payload = montaParametros(params);

		http
			.get(`/${CONTROLLER_NAME}`, { params: payload })
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

const montaParametros = (param) => {
	const params = new URLSearchParams();
	if (param) {
		param.user && params.append("text", param.user);
		param.statusId && params.append("status", param.statusId);
		param.pageNum && params.append("page", param.pageNum ? param.pageNum : 0);
		param.size && params.append("size", param.size ? param.size : 10);
		param.sortBy && params.append("sortBy", param.sortBy ? param.sortBy : "name");
		param.sortType && params.append("sortType", param.sortType ? param.sortType : "asc");
	}

	return params;
};

const getOwnData = () => {
	return new Promise((resolve, reject) => {
		http
			.get(`/${CONTROLLER_NAME}/me`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

const updateMe = (param) => {
	return new Promise((resolve, reject) => {
		http
			.put(`/${CONTROLLER_NAME}/me`, param)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

const importUsers = (values) => {
	return new Promise((resolve, reject) => {
		const formData = new FormData();
		formData.append("file", values.file);
		http
			.post(`/${CONTROLLER_NAME}/import`, formData)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

export const exportUsers = (params) => {
	return new Promise((resolve, reject) => {
		http
			.get(`/${CONTROLLER_NAME}/export`, { params, responseType: "arraybuffer" })
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

const getOwnSupplier = () => {
	return new Promise((resolve, reject) => {
		http
			.get(`/${CONTROLLER_NAME}/suppliers`)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

export default {
	registerPassword,
	sendUserTem,
	sendPreRegister,
	registerForm,
	getUserById,
	updateUser,
	rejectUser,
	aproveUser,
	searchUsers,
	getOwnData,
	updateMe,
	getOwnSupplier,
	importUsers,
	exportUsers,
};
