import { Divider, List, ListItem, ListItemText, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Labels from "~/helpers/enums/Labels";

const NotificationList = (props) => {
	const { classes } = props;
	const { notifications } = useSelector((store) => store.notification);
	const [notif, setNotif] = useState([]);
	const { t } = useTranslation();

	dayjs.locale(t(Labels.LOCALE));

	useEffect(() => {
		setNotif((store) => notifications);
	}, [notifications]);

	return (
		<List id="notificationList">
			{notif && notif.length > 0 ? (
				notif.map((notification, index) => {
					let dividerVisible = index !== notif.length - 1;
					let date = new Date(notification.date);
					return (
						<Fragment key={`fragment1-${notification.id}`}>
							<ListItem key={`list-item-${notification.id}`} alignItems="flex-start">
								<ListItemText
									id={notification.id}
									key={`list-item-text-${notification.id}`}
									primary={
										<Typography
											component="span"
											key={`typography-primary-1-${notification.id}`}
											className={classes.subject}>
											{notification.subject}
										</Typography>
									}
									secondary={
										<Fragment key={`fragment2-${notification.id}`}>
											<Typography
												component="span"
												key={`typography-secondary-1-${notification.id}`}
												className={classes.message}>
												{notification.message}
											</Typography>
											<Typography
												component="span"
												key={`typography-secondary-2-${notification.id}`}
												className={classes.relativeDate}>
												{`   ${dayjs(date.toLocaleString(), "DD/MM/YYYY hh:mm:ss").fromNow()}`}
											</Typography>
										</Fragment>
									}
								/>
							</ListItem>
							{dividerVisible && <Divider key={`divider-${notification.id}`} variant="inset" component="li" />}
						</Fragment>
					);
				})
			) : (
				<ListItem alignItems="flex-start">
					<ListItemText key={0} primary="Você não tem notificações" />
				</ListItem>
			)}
		</List>
	);
};

export default NotificationList;
