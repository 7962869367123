import React from "react";
import lodash from "lodash";
import { Autocomplete } from "@material-ui/lab";
import { TextField, Checkbox, FormControlLabel, CircularProgress, Typography, Tooltip } from "@material-ui/core";
import { fieldToTextField } from "formik-material-ui";
import "./FormikAutocomplete.scss";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListBox from "./ListBox";
import MuiStyleAutoComplete from "./MuiStyleAutoComplete";
import clsx from "clsx";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FormikAutocompleteCheckbox = ({ textFieldProps, loading, helper = {}, options, ...props }) => {
	const {
		form: { setTouched, setFieldValue },
	} = props;
	const { error, helperText, ...field } = fieldToTextField(props);
	const { name } = field;
	const { groupBy, disabledCheckboxBy, labelFormat } = helper;
	const classes = MuiStyleAutoComplete();

	const render = (option, { selected }) => {
		return (
			<FormControlLabel
				className={classes.checkboxChildren}
				control={<Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />}
				label={
					labelFormat ? (
						labelFormat(option)
					) : (
						<Typography noWrap>{option["id"] + " - " + option[helper.label]}</Typography>
					)
				}
			/>
		);
	};

	const handleGroupChange = (option, groupValue) => {
		const marked = helper.markedItens;
		const allItens = options;
		let itens = [];
		if (groupValue) {
			itens = allItens.filter((val) => val[groupBy] === option.group);
			itens = lodash.unionBy(itens, marked, "id");
		} else {
			itens = marked.filter((val) => val[groupBy] !== option.group);
		}
		setFieldValue(name, itens);
	};

	const isAllChildMarked = (option) => {
		const marked = helper.markedItens.filter((val) => val[groupBy] === option.group);
		const allItens = options.filter((val) => val[groupBy] === option.group);
		return marked.length === allItens.length;
	};

	const disableCheckboxGroup = (option) => {
		return !options.find((val) => val[groupBy] === option.group)[disabledCheckboxBy];
	};

	const renderGroup = (option) => {
		let checked = false;
		const disableCheckbox = disabledCheckboxBy ? disableCheckboxGroup(option) : false;

		if (!disableCheckbox) {
			checked = isAllChildMarked(option);
		}
		return [
			<ListSubheader
				key={option.group}
				className={clsx({
					[classes.checkboxCombo]: true,
					[classes.checkboxTrue]: checked,
				})}>
				<Tooltip title={option.group}>
					<FormControlLabel
						control={
							<Checkbox
								disabled={disableCheckbox}
								icon={icon}
								indeterminate={disableCheckbox}
								checkedIcon={checkedIcon}
								checked={checked || false}
								onChange={() => {
									setFieldValue(`optionGroup.${option.group}`, !checked);
									handleGroupChange(option, !checked);
								}}
								color="primary"
							/>
						}
						label={option.group}
					/>
				</Tooltip>
			</ListSubheader>,
			option.children,
		];
	};

	return (
		<Autocomplete
			{...props}
			{...field}
			loading={loading}
			ListboxComponent={ListBox}
			loadingText={"carregando..."}
			options={options}
			onChange={(event, value, reason) => {
				if (reason === "remove-option" && event.type === "keydown") {
					return;
				}
				setFieldValue(name, value);
				props.onChange && props.onChange(event, value);
			}}
			onBlur={() => setTouched({ [name]: true })}
			renderOption={helper ? render : null}
			renderGroup={renderGroup}
			renderInput={(prop) => {
				const startAdornment = prop.InputProps.startAdornment;
				prop.InputProps.startAdornment = null;

				return (
					<>
						<TextField
							{...prop}
							{...textFieldProps}
							InputProps={{
								...prop.InputProps,
								endAdornment: (
									<React.Fragment>{loading ? <CircularProgress color="inherit" size={20} /> : null}</React.Fragment>
								),
								...textFieldProps.InputProps,
							}}
							helperText={helperText}
							error={error}
						/>
						<div className={classes.chip}> {startAdornment}</div>
					</>
				);
			}}
		/>
	);
};

export default FormikAutocompleteCheckbox;
