import {
	Badge,
	Box,
	Container,
	Grid,
	IconButton,
	LinearProgress,
	Popover,
	Toolbar,
	Typography,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import appAction from "~/actions/appAction";
import authAction from "~/actions/authAction";
import notificationAction from "~/actions/notificationAction";
import AcceptanceTerm from "~/components/acceptanceTerm/AcceptanceTerm";
import CustomDialog from "~/components/common/customDialog/CustomDialog";
import ConfirmDialog from "~/components/common/dialog/ConfirmDialog";
import { BlueLogoIcon, NotificationIcon } from "~/components/common/icons/Icons";
import NotificationList from "~/components/common/notificationList/NotificationList";
import DialogSupplier from "~/components/DialogSupplier/DialogSupplier";
import Constants from "~/helpers/enums/Constants";
import Labels from "~/helpers/enums/Labels";
import Utils from "~/helpers/Utils";
import DropdownProfile from "./DropdownProfile";
import "./Layout.scss";
import MuiStylesBaseLayout from "./MuiStylesBaseLayout";
import { anonymousTheme, authenticatedTheme } from "./MuiThemeBaseLayout";
import MuiThemeBaseLayoutNotification from "./MuiThemeBaseLayoutNotification";

const AuthenticatedLayout = (props) => {
	const dispatch = useDispatch();
	const classes = MuiStylesBaseLayout();
	const history = useHistory();
	const { loading, dialog, background, customDialog } = useSelector((state) => state.app);
	const { user, acceptanceTerm, isTemporaryPassword, isAuthenticated } = useSelector((state) => state.auth);
	const { totalUnread } = useSelector((store) => store.notification);
	const [anchorEl, setAnchorEl] = useState(null);
	const [newNotifications, setNewNotifications] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		if (!user) dispatch(authAction.getProfile());
	}, [dispatch, user]);

	useEffect(() => {
		if (totalUnread > 0) {
			setNewNotifications(true);
		}
	}, [totalUnread]);

	useEffect(() => {
		const timer = setTimeout(() => dispatch(notificationAction.updateNotifications()), Constants.REFRESH_TIMEOUT);
		return () => clearTimeout(timer);
	});

	useEffect(() => {
		if (isAuthenticated) {
			dispatch(notificationAction.updateNotifications());
		}
	}, [user, isAuthenticated, dispatch]);

	useEffect(() => {
		if (`${isTemporaryPassword}` === "true") {
			history.push("/register-password");
		}
	}, [isTemporaryPassword, history]);

	useEffect(() => {
		if (`${acceptanceTerm}` === "false")
			dispatch(
				appAction.openConfirmDialog({
					title: t(Labels.USER_TERM_TITLE),
					children: <AcceptanceTerm />,
					maxWidth: "lg",
					buttonCancelDisabled: true,
					buttonOk: t(Labels.DIALOG_CONFIRM_ACCEPT),
					loading: loading.acceptTerm,
					onOk: () => {
						dispatch(
							authAction.sendUserTem(() => {
								Utils.setToken(true, Constants.TOKEN_ACCEPT_TEM);
								dispatch(appAction.closeConfirmDialog());
							})
						);
					},
				})
			);
	}, [t, dispatch, loading, acceptanceTerm]);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
		setNewNotifications(false);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return (
		<ThemeProvider theme={authenticatedTheme}>
			<Box className="ceabs-layout">
				<Box className="ceabs-header">
					<Toolbar>
						<div className="logo-container">
							<Link to="/">
								<BlueLogoIcon />
							</Link>
						</div>
						<Typography className="header-title" variant="h5" noWrap>
							{t(Labels.LOGIN_TITLE)}
						</Typography>

						{`${isTemporaryPassword}` === "false" && (
							<Grid className="profile">
								<Grid className="icon-notification">
									<IconButton onClick={handleClick}>
										<Badge
											className={classes.notificationBadge}
											variant="dot"
											invisible={!newNotifications}
											anchorOrigin={{
												vertical: "bottom",
												horizontal: "left",
											}}>
											<NotificationIcon />
										</Badge>
									</IconButton>
								</Grid>
								<ThemeProvider theme={MuiThemeBaseLayoutNotification}>
									<Popover
										id="notificationPopover"
										open={open}
										anchorEl={anchorEl}
										onClose={handleClose}
										anchorOrigin={{
											vertical: "bottom",
											horizontal: "right",
										}}
										transformOrigin={{
											vertical: "top",
											horizontal: "right",
										}}
										style={{ width: "40%", height: "50%" }}>
										<NotificationList classes={classes} />
									</Popover>
								</ThemeProvider>
								<Grid item xs={12} md={12} className="username-container">
									<Typography variant="body2" className="username">
										{user && user.name}
										<DropdownProfile />
									</Typography>
								</Grid>
							</Grid>
						)}
					</Toolbar>
					<Grid>{Object.values(loading).some((x) => x) && <LinearProgress />}</Grid>
				</Box>
				<Box className="ceabs-main">
					<Box className={`ceabs-content ${background}`}>
						<Container maxWidth={false}>{props.children} </Container>
					</Box>
				</Box>
			</Box>

			<ToastContainer style={{zIndex: 99999}}/>
			<DialogSupplier />
			<ConfirmDialog {...dialog} />
			<CustomDialog
				{...customDialog}
				onClose={() => dispatch(appAction.closeCustomDialog())}
				id="baseLayout"
				children={customDialog.message}
			/>
		</ThemeProvider>
	);
};

const AnonymousLayout = (props) => {
	const { loading } = useSelector((state) => state.app);

	return (
		<ThemeProvider theme={anonymousTheme}>
			{loading.header && <LinearProgress />}
			{props.children}
			<ToastContainer style={{zIndex: 99999}}/>
		</ThemeProvider>
	);
};

export default { AuthenticatedLayout, AnonymousLayout };
