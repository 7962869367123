import React from "react";
import { useDispatch } from "react-redux";
import {
	Button,
	DialogTitle,
	DialogContent,
	DialogActions,
	Dialog,
	Typography,
	Grid,
	LinearProgress,
	IconButton,
} from "@material-ui/core";
import Labels from "~/helpers/enums/Labels";
import appAction from "~/actions/appAction";
import { useTranslation } from "react-i18next";
import "./Dialog.scss";
import { CloseIcon } from "../icons/Icons";

const ConfirmDialog = (props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const handleClose = () => {
		dispatch(appAction.closeConfirmDialog());
	};

	const handleCancel = () => {
		handleClose();
		props.onCancel && props.onCancel();
	};

	const handleOk = () => {
		if (props.closeWhenOk) handleClose();
		props.onOk && props.onOk();
	};

	return (
		<Dialog
			disableBackdropClick
			disableEscapeKeyDown
			fullWidth
			maxWidth={props.maxWidth}
			className="ceabs-layout ceabs-dialog"
			open={props.open}>
			{props.title && (
				<DialogTitle>
					{props.title}
					{props.closeable && (
						<IconButton
							className="close-button"
							onClick={handleClose}>
							<CloseIcon />
						</IconButton>
					)}
				</DialogTitle>
			)}
			<DialogContent dividers>
				<Grid item xs={12} md={12}>
					{props.question && <Typography variant="body1"> {props.question} </Typography>}
				</Grid>
				{props.loading && <LinearProgress />}
				<Grid container item xs={12} md={12} alignItems="center">
					{props.children}
				</Grid>
			</DialogContent>
			{(!props.buttonCancelDisabled || !props.buttonOkDisabled) && (
				<DialogActions>
					{!props.buttonCancelDisabled && (
						<Button
							disabled={props.loading}
							onClick={handleCancel}
							className="cancel-button"
							{...props.buttonCancelProps}>
							{props.buttonCancel || t(Labels.DIALOG_CONFIRM_CANCEL)}
						</Button>
					)}

					{!props.buttonOkDisabled && (
						<Button
							disabled={props.loading}
							onClick={handleOk}
							color="primary"
							className="ok-button"
							{...props.buttonOkProps}>
							{props.buttonOk || t(Labels.DIALOG_CONFIRM_YES)}
						</Button>
					)}
				</DialogActions>
			)}
		</Dialog>
	);
};

export default ConfirmDialog;
