import Constants from "~/helpers/enums/Constants";
import notificationService from "~/services/notificationService";

const updateNotifications = () => (dispatch) => {
	return notificationService.getNotifications().then((resp) => {
		dispatch({
			type: Constants.UPDATE_NOTIFICATIONS,
			payload: resp.data,
		});
	});
};

export default {
	updateNotifications,
};
