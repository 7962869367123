import lodash from "lodash";
import Constants from "~/helpers/enums/Constants";
import Utils from "~/helpers/Utils";
import supplierService from "~/services/supplierService";

const openSupplierDialog = (params) => (dispatch) => {
	dispatch({ type: Constants.OPEN_SUPPLIER_DIALOG, payload: { params } });
};

const closeSupplierDialog = () => (dispatch) => {
	dispatch({ type: Constants.CLOSE_SUPPLIER_DIALOG });
};

const saveSupplierFilter = (suppliers) => (dispatch) => {
	dispatch({ type: Constants.SAVES_SUPPLIER_FILTER, payload: { ...suppliers } });
};

const getSuppliers = (filter, callback = () => {}, LOADING_IDENTIFICATOR = "") => (dispatch) => {
	dispatch(Utils.startLoading(LOADING_IDENTIFICATOR));
	return supplierService
		.getSupplier(filter)
		.then((response) => {
			if (response) {
				dispatch({
					type: Constants.GET_SUPPLIER,
					payload: lodash.orderBy(response.data.suppliers, "groupDescription", "asc"),
				});
				callback(response.data.suppliers);
			}
		})
		.catch((error) => {
			const data = error?.response?.data || {};
			const msg = data.message || null;
			callback(msg);
		})
		.finally(() => {
			dispatch(Utils.endLoading(LOADING_IDENTIFICATOR));
		});
};

const clearSuppliers = () => (dispatch) => {
	dispatch({ type: Constants.CLEAR_SUPPLIER });
};

export default { openSupplierDialog, closeSupplierDialog, saveSupplierFilter, getSuppliers, clearSuppliers };
