export const FORNECEDOR_PRE_CADASTRAR = "FORNECEDOR_-_PRE_CADASTRAR";
export const ADMIN_CADASTRAR_USUARIO = "ADMIN_-_CADASTRAR_USUARIO";
export const ADMIN_APROVAR_USUARIO = "ADMIN_-_APROVAR_USUARIO";
export const CNPJ_CONSULTAR = "CNPJ_-_CONSULTAR";
export const NFS_CONSULTAR = "NFS_-_CONSULTAR";
export const CONTRATOS_CONSULTAR = "CONTRATOS_-_CONSULTAR";
export const RUBRICAS_EXECUTOR = "RUBRICAS_-_EXECUTOR";
export const RUBRICAS_CONSULTAR = "RUBRICAS_-_CONSULTAR";
export const NFS_EXECUTOR = "NFS_-_EXECUTOR";
export const ANTECIPACAO_CONSULTAR = "ANTECIPACAO_-_CONSULTAR";
export const ANTECIPACAO_EXECUTOR = "ANTECIPACAO_-_EXECUTOR";
export const CONTRATOS_EXECUTOR = "CONTRATOS_-_EXECUTOR";
export const ADMIN_APROVAR_ANTECIPACAO = "ADMIN_-_APROVAR_ANTECIPACAO";
export const DATA_SHARING = "DATA_-_SHARING";
export const REAJUSTE_TABELA = "REAJUSTE_-_TABELA";
export const TABELA_PRECOS_CONSULTAR = "TABELA_PRECOS_-_CONSULTAR";
export const TABELA_PRECOS_EXECUTOR = "TABELA_PRECOS_-_EXECUTOR";

export default {
	FORNECEDOR_PRE_CADASTRAR,
	ADMIN_CADASTRAR_USUARIO,
	ADMIN_APROVAR_USUARIO,
	CNPJ_CONSULTAR,
	NFS_CONSULTAR,
	CONTRATOS_CONSULTAR,
	RUBRICAS_EXECUTOR,
	NFS_EXECUTOR,
	ANTECIPACAO_EXECUTOR,
	CONTRATOS_EXECUTOR,
	ANTECIPACAO_CONSULTAR,
	ADMIN_APROVAR_ANTECIPACAO,
	RUBRICAS_CONSULTAR,
	DATA_SHARING,
	REAJUSTE_TABELA,
	TABELA_PRECOS_CONSULTAR,
	TABELA_PRECOS_EXECUTOR
};
