import Constants from "~/helpers/enums/Constants";

const INITIAL_STATE = {
	detailContract: {},
};

function contractReducer(state = INITIAL_STATE, action = null) {
	switch (action.type) {
		case Constants.GET_CONTRACT_DETAIL:
			return {
				...state,
				detailContract: action.payload,
			};
		default:
			return state;
	}
}

export default contractReducer;
